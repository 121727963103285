<template>
  <div class="PersonalCenter">
    <Header/>
    <div class="PersonalCenterWrap">
      <div class="conetnet">
        <userInfo v-show="option!==10" :option="option" :quxiaos="quxiao" @option="eventOption"/>
        <resume v-if="option === 0" @openShiBie="openShiBie"/>
        <interviewList :statuss="option" v-show="option === 1 || option === 2 || option === 4"/>
        <collection :statuss="option" v-if="option === 3" @cancel="cancel"/>
        <Topping :statuss="option" v-show="option === 5"/>
        <InterviewRecords :statuss="option" v-show="option === 7"/>
        <integral :statuss="option" v-if="option === 9"/>
        <attachment :statuss="option" v-if="option === 10"/>
      </div>
      <Right @option="eventOption" :openFu="dialogVisible"/>
    </div>
    <Footer/>
    <floatRight/>
    <logins v-model="isShow2"/>
  </div>
</template>
<script>
import Header from "@/components/headers/index.vue";
import Right from "@/views/PersonalCenter/right.vue";
import userInfo from "@/views/PersonalCenter/userInfo.vue";
import resume from "@/views/PersonalCenter/resume.vue";
import Footer from "@/components/footer/index.vue";
import floatRight from "@/components/floatRight/vue.vue";
import interviewList from "@/views/PersonalCenter/InterviewList.vue";
import collection from "@/views/PersonalCenter/collection.vue";
import Topping from "@/views/PersonalCenter/Topping.vue";
import InterviewRecords from "@/views/PersonalCenter/InterviewRecords.vue";
import integral from "@/views/PersonalCenter/integral.vue";
import attachment from "@/views/PersonalCenter/attachment.vue";
import {getCurrentResume} from "@/api/currency";
import logins from "@/components/login/index.vue";

export default {
  components: {
    logins,
    floatRight,
    attachment,
    Footer,
    Header,
    Right,
    userInfo,
    resume,
    integral,
    interviewList,
    collection,
    Topping,
    InterviewRecords
  },
  data() {
    return {
      option: 0,
      quxiao: 0,
      dialogVisible: false,
    }
  },
  watch: {
    // 监视搜索词变化
    "$route.query.id": {
      immediate: true,
      handler() {
        console.log(this.$route.query.id)
        if(this.$route.query.id ==undefined) {
          this.option = 0
          return
        }
        if(this.$route.query.id) {
          this.option = Number(this.$route.query.id)
        }
      },
    },
  },
  computed: {
    isShow2() {
      return this.$store.getters.showLogin
    },
  },
  created() {
    if (this.$route.query.id) {
      this.option = Number(this.$route.query.id)
    }
    this.getCurrentResume()
  },
  methods: {
    openShiBie() {
      this.dialogVisible = !this.dialogVisible
    },
    cancel() {
      this.quxiao = this.quxiao + 1
    },
    async getCurrentResume() {
      const {data} = await getCurrentResume()
      await this.$store.commit('userInfo', data[0])
    },
    eventOption(el) {
      this.option = el
    }
  }
}
</script>


<style scoped lang="scss">
.PersonalCenter {
  background: #F5F5F6;
  width: 100%;
  overflow: hidden;
  z-index: 9;

  .PersonalCenterWrap {
    position: relative;
    z-index: 9;
    min-height: calc(100vh - 154px);

    width: 1200px;
    margin: 70px auto 22px auto;
    display: flex;
    gap: 15px;

    .conetnet {
      width: 900px;
      display: flex;
      flex-direction: column;
      gap: 16px;

    }
  }
}
</style>
