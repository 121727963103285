<template>
  <div class="InterviewRecords">
    <div class="nav" v-if="statuss !==3">
      <div class="title">
        <span>面试记录</span>
      </div>
      <div class="screenWrap">
        <div class="item">
          <div class="name">地点</div>
          <el-select v-model="value" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="name">状态</div>
          <el-select v-model="value" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="name">时间</div>
          <el-select v-model="value2" placeholder="请选择">
            <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in list" :key="index" @click="openDialog">
        <div class="top">
          <div class="left">
            <div class="nameOrPrice">
              <div class="name">算法工程师</div>
              <div class="price">11-20k 13薪</div>
            </div>
            <div class="infoList">
              <div class="itemss">
                <img src="https://static.dasdt.cn/images/adress3.png">
                <span>上海·静安</span>
              </div>
              <div class="itemss">
                <img src="https://static.dasdt.cn/images/education.png">
                <span>本科</span>
              </div>
              <div class="itemss">
                <img src="https://static.dasdt.cn/images/experience.png">
                <span>3-5年</span>
              </div>
            </div>
          </div>
          <div class="enterpriseInfo">
            <img src="https://static.dasdt.cn/images/enterpriseLogo.png" alt="">
            <div class="right">
              <div class="name">拉扎斯网络科技(上海)有限公司</div>
              <div class="tag">国企·高新企业·五百强</div>
            </div>
          </div>
<!--          <div class="status">-->
<!--            已投递-->
<!--          </div>-->
          <div class="status2" >
            初试
          </div>
        </div>
        <div class="borders" v-if="statuss !== 4"></div>
        <div class="bottom" v-if="statuss !== 4">
          <div class="timer">投递时间：2024-02-28 10:00:23</div>
          <div class="btns">
            <div  class="detail" @click.stop="dialogVisible3 = true">取消面试</div>
            <div class="detail" @click.stop="openDetails">查看详情</div>
            <div  class="btn1" @click.stop="jieshu">面试结束</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :visible.sync="dialogVisible"
        :modal-append-to-body='false'
        width="905px"
        :before-close="handleClose">
      <div class="detailss">
        <div class="titles" style="margin-top: 0px">岗位描述</div>
        <div class="tagList">
          <div class="item">应届生/在校生</div>
          <div class="item">本科</div>
          <div class="item">JAVA</div>
        </div>
        <div class="responsibilityWrap">
          <div class="responsibility">岗位职责：</div>
          <div class="responsibility">根据TAPS产品战略规划，开发算法，满足TAPS产品的要求；</div>
          <div class="responsibility">参与TAPS解决方案的编写；负责对现场实施的项目提供技术支持</div>
        </div>
        <div class="responsibilityWrap">
          <div class="responsibility">任职要求：</div>
          <div class="responsibility">1.熟悉嵌入式开发、实时性操作系统，并且至少掌握一门开发语言，如C、C++、python等。</div>
          <div class="responsibility">2.熟悉Linux，具备在linux下开发与调试的能力，，了解Vxworks，QNX，Reworks等实时操作系统。</div>
          <div class="responsibility">3.熟悉且掌握以下至少一种算法技术：</div>
          <div class="responsibility">- 激光点云SLAM技术及相关算法，如Loam，NDT，ICP等，能熟练使用PCL、ROS等组件</div>
          <div class="responsibility">- 了解人工智能的基本原理，熟悉计算机视觉的运用，至少掌握一种深度学习框架，如Tensorflow、Torch等，对目标检测、语义分割的相关模型如：Yolo、DeepLab、FastRCNN、Transformer等有实操经验，对模型的搭建和针对性修改有经验者优先，熟练掌握opencv等开源库。有模型剪枝、量化、加速等相关经验，使用过onnx，TensorRT框架等。研究或了解过多任务学习，多模态等</div>
          <div class="responsibility">- 熟悉自然语言处理NLP相关技术，如GPT、Bert等，有实际项目应用经验</div>
          <div class="responsibility">4.备创新精神，不畏困难，遇到问题勇于承担，用于钻研，不轻言放弃；善于与人交流沟通，乐于分享，有团队精神等品质</div>
        </div>
        <div class="borderss"></div>
        <div class="titles" style="margin-top: 20px">公司信息</div>
        <div class="companyInfo">
          <img src="https://static.dasdt.cn/images/enterpriseLogo.png">
          <div class="right" style="cursor: pointer" @click.stop="goDetails">
            <div class="name">上海幻电信息科技有限公司</div>
            <div class="desc">国企·高新企业·50-100人</div>
          </div>
        </div>
        <div class="borderss"></div>
        <div class="titles" style="margin-top: 20px">工作地址</div>
        <div class="adresss">
          <div class="item">
            <img src="https://static.dasdt.cn/images/adress2.png">
            <span class="adressShengLue">上海市静安区智慧产业园212</span>
          </div>
          <div class="item">
            <img style="margin-right: 2px" src="https://static.dasdt.cn/images/phone2.png">
            <span>联系电话：021-12321232</span>
          </div>
        </div>
        <div style="height: 300px;width: 100%;margin-top: 16px">
          <MapContainer/>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        :modal-append-to-body='false'
        :visible.sync="dialogVisible2"
        width="485px"
        title="初试详情"
        :before-close="handleClose2">
      <div class="InterviewDetalis">
        <div class="table">
          <div class="item">
            <div class="title">面试时间</div>
            <div class="value">2023.08.07｜周三｜9:00 - 11:00</div>
          </div>
          <div class="item">
            <div class="title">面试官</div>
            <div class="value">李兰、张衡、刘松</div>
          </div>
          <div class="item">
            <div class="title">面试岗位</div>
            <div class="value">万达广场c座204室</div>
          </div>
          <div class="item">
            <div class="title">联系电话</div>
            <div class="value">189 2390 3092</div>
          </div>
          <div class="item">
            <div class="title">面试状态</div>
            <div class="btn">已完成</div>
          </div>
        </div>
        <div class="btns">
          <div class="box1">取消面试</div>
          <div class="box2">结束面试</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        :modal-append-to-body='false'
        :visible.sync="dialogVisible3"
        width="400px"
        title="取消面试"
        :before-close="handleClose3">
      <div class="tishi">
        <el-checkbox-group class="checkboxGroup" v-model="checkList">
          <el-checkbox label="1">已找到工作</el-checkbox>
          <el-checkbox label="2">个人发展有新认识，无法匹配公司的发展战略</el-checkbox>
          <el-checkbox label="3">紧急事务需要处理</el-checkbox>
        </el-checkbox-group>
        <div class="textareas">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入其他原因信息"
              v-model="textarea">
          </el-input>
        </div>

        <div class="btns">
          <div class="left">取消</div>
          <div class="right">确认取消</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        :modal-append-to-body='false'
        :visible.sync="dialogVisible4"
        width="400px"
        title="提示"
        :before-close="handleClose4">
      <div class="tishi">
        <img src="https://static.dasdt.cn/images/userCenter/tishi.png">
        <div class="desc">您是否已决定结束面试？</div>
        <div class="desc2">结束后, 将不再进行面试</div>
        <div class="btns">
          <div class="left">取消</div>
          <div class="right">确认取消</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import MapContainer from "@/components/MapContainer/index.vue";

export default {
  components: {MapContainer},
  props: {
    statuss: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      textarea: '',
      checkList: [1],
      value: '',
      value2: '',
      list: [
        {},
        {},
      ],
      options: [
        {
          value: 1,
          label: '已投递'
        }
      ],
      options2: [
        {
          value: 1,
          label: '三天内'
        }
      ],
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
    }
  },
  methods: {
    openDialog() {
      console.log(1111)
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    // 跳转企业详情
    goDetails() {
      this.$router.push('/enterpriseDetails')
    },
    openDetails() {
      this.dialogVisible2 = true
    },
    handleClose2() {
      this.dialogVisible2 = false
    },
    handleClose3() {
      this.dialogVisible3 = false
    },
    jieshu() {
      this.dialogVisible4 = true

    },
    handleClose4() {
      this.dialogVisible4 = false
    },
  }
}
</script>


<style lang="scss">
.InterviewRecords {
  .tishi {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .textareas{
      width: 100%;
      margin-bottom: 30px;
      .el-textarea__inner{
        border: none !important;
        background: #F3F3F3 !important;
        border-radius: 8px !important;
      }
    }
    .checkboxGroup{
      display: flex;flex-direction: column;gap: 30px;
      margin-top: 15px;
      margin-bottom: 20px;
    }

    img {
      width: 80px;
      height: 80px;
    }

    .desc {
      color: #333333;
      font-size: 20px;
      font-weight: 500;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .desc2 {
      margin-bottom: 50px;
      color: #808080;
      font-size: 14px;
    }

    .btns {
      display: flex;
      align-items: center;
      gap: 10px;

      .left {
        cursor: pointer;
        width: 175px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: 1px solid #ADADAD;
        border-radius: 100px;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
      }

      .right {
        cursor: pointer;
        width: 175px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #3A76FF;
        border-radius: 100px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .InterviewDetalis {
    padding-bottom: 20px;

    .btns {
      margin-top: 20px;
      display: flex;
      align-items: center;
      gap: 10px;

      .box1 {
        border: 1px solid #ADADAD;
        cursor: pointer;
        width: 214px;
        height: 44px;
        border-radius: 100px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
      }

      .box2 {
        cursor: pointer;
        width: 214px;
        height: 44px;
        border-radius: 100px;
        background: #3A76FF;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .table {
      display: flex;
      flex-direction: column;

      .item {
        border-bottom: 1px solid #E3E4E7;
        height: 64px;
        display: flex;
        align-items: center;

        .title {
          width: 178px;
          color: #808080;
          font-size: 16px;
          font-weight: 400;
        }

        .value {
          color: #333333;
          font-size: 16px;
          font-weight: 400;
        }

        .btn {
          padding: 6px 8px;
          background: rgba($color: #000000, $alpha: 0.04);
          border-radius: 2px;
          color: rgba($color: #000000, $alpha: 0.45);
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .detailss {
    border-radius: 8px;
    padding: 16px;
    background: #fff;

    .ViewMores {
      margin: 32px auto;
      width: 200px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #185BE0;
      color: #185BE0;
      font-size: 14px;
      font-weight: 400;
      border-radius: 4px;
      cursor: pointer;
    }

    .adresss {
      display: flex;
      align-items: center;

      .item {
        width: 50%;
        display: flex;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          color: #555555;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .companyInfo {
      display: flex;
      align-items: center;
      gap: 6px;

      img {
        width: 44px;
        height: 44px;
      }

      .right {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .name {
          color: #333333;
          font-size: 16px;
          font-width: 500;
        }

        .desc {
          color: #808080;
          font-size: 14px;
          font-width: 400;
        }
      }
    }

    .borderss {
      margin-top: 20px;
      border-bottom: 1px solid #E6E6E6;
    }

    .responsibilityWrap {
      margin-top: 20px;

      .responsibility {
        color: #333333;
        font-size: 14px;
        line-height: 26px;
        font-width: 400;
      }
    }

    .titles {
      margin-bottom: 16px;
      margin-top: 32px;
      color: #141414;
      font-size: 18px;
      font-width: 500;

    }
  }

  .list {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .item {
      cursor: pointer;
      background: #ffffff;
      border-radius: 8px;
      padding: 16px;

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btns {
          display: flex;
          align-items: center;
          gap: 30px;

          .btn1 {
            cursor: pointer;
            color: #3A76FF;
            font-size: 14px;
            font-weight: 400;
          }

          .detail {
            cursor: pointer;
            color: #808080;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .timer {
          color: #808080;
          font-size: 14px;
          font-weight: 400;
        }

      }

      .borders {
        margin: 14px 0;
        border: 1px solid rgba($color: #E6E6E6CC, $alpha: 0.8);
      }

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .status2 {
          background: rgba($color: #F67830, $alpha: 0.15);
          border-radius: 0 8px 0 8px;
          padding: 4px 10px 4px 10px;
          color: #F67830;
          font-size: 14px;
          font-weight: 400;
        }

        .status3 {
          background: rgba($color: #FAAD1426, $alpha: 0.15);
          border-radius: 0 8px 0 8px;
          padding: 4px 10px 4px 10px;
          color: #FAAD1426;
          font-size: 14px;
          font-weight: 400;
        }

        .status {
          background: #F8F8F8;
          border-radius: 0 8px 0 8px;
          padding: 4px 10px 4px 10px;
          color: #ADADAD;
          font-size: 14px;
          font-weight: 400;
        }

        .enterpriseInfo {
          display: flex;
          align-items: center;
          gap: 6px;

          img {
            width: 40px;
            height: 40px;
          }

          .right {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .name {
              font-size: 16px;
              color: #282828;
              font-weight: 500;
            }

            .tag {
              color: #808080;
              font-size: 14px;
              line-height: 16px;
            }
          }
        }

        .left {
          .infoList {
            margin-top: 10px;
            display: flex;
            align-items: center;
            gap: 24px;

            .itemss {
              display: flex;
              align-items: center;
              gap: 6px;

              img {
                width: 16px;
                height: 16px;
              }

              span {
                color: rgba($color: #000000, $alpha: 0.65);
                font-size: 14px;
                font-width: 400;
              }
            }
          }

          .nameOrPrice {
            display: flex;
            align-items: center;
            gap: 14px;

            .name {
              color: #141414;
              font-size: 16px;
              font-weight: 500;
            }

            .pirce {
              color: #FD5056;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }

      }
    }
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .screenWrap {
      display: flex;
      align-items: center;
      gap: 12px;

      .item {
        display: flex;
        align-items: center;
        gap: 6px;

        .name {
          color: #555555;
          font-size: 14px;
          font-weight: 400;
        }

        .el-select {
          height: 32px !important;
          width: 126px !important;
        }

        .el-input__suffix {
          height: 37px;
        }

        .el-input__inner {
          height: 32px !important;
          line-height: 32px !important;
        }
      }
    }

    .title {
      color: #555555;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
</style>
