<template>
  <div class="integral">
    <div class="title">我的积分</div>
    <div class="numList">
      <div class="item">
        <div class="num">{{ integral?.totalIntegral }}</div>
        <div class="name">总获得积分</div>
      </div>
      <div class="item">
        <div class="num">{{ integral?.residualIntegral }}</div>
        <div class="name">剩余积分</div>
      </div>
      <div class="item">
        <div class="num">0</div>
        <div class="name">支出积分</div>
      </div>
    </div>
    <div class="content">
      <el-tabs v-model="activeName" @tab-click="eventOption">
        <el-tab-pane label="收入记录" name="1">
        </el-tab-pane>
        <el-tab-pane label="支出记录" name="2">
        </el-tab-pane>
      </el-tabs>
      <div class="expenditureList">
        <template v-if="integralMxList.length!==0">
          <div class="item" v-for="item in integralMxList" :key="item.dv">
            <div class="header">
              <div class="title">{{item.monthGroup}}</div>
              <div class="total">共收入{{item.integralCount}}</div>
            </div>
            <div class="detail">
              <div class="its" v-for="its in item.info" :key="its.id">
                <div class="left">
                  <div class="name">{{its.mx}}</div>
                  <div class="time">{{its.createTimeFormat}}</div>
                </div>
                <div class="price">+{{its.integral}}</div>
              </div>
            </div>
          </div>

        </template>
        <el-empty v-else description="暂无数据"></el-empty>

      </div>
    </div>
  </div>
</template>
<script>
import {integralInfo, integralMx} from "@/api/position";

export default {
  data() {
    return {
      activeName: '1',
      integral: {},
      pagination: {
        number: 1,
        limit: 5
      },
      totalCount: 0,
      integralMxList: [],
      earning: true
    }
  },
  created() {
    this.integralInfo()
    this.integralMx()
  },
  methods: {
    async integralInfo() {
      const {data} = await integralInfo()
      console.log(data)
      this.integral = data[0]
    },
    eventOption() {
      if(this.activeName === '1') {
        this.earning = true
      }else{
        this.earning = false
      }
      this.integralMx()
    },
    async integralMx() {
      const {data} = await integralMx({earning: this.earning}, this.pagination)
      console.log(data)
      this.integralMxList = data
    },
  }
}
</script>
<style lang="scss">
.integral {
  .content {
    margin-top: 16px;
    background: #fff;
    border-radius: 8px;
    padding: 16px;

    .expenditureList {
      margin-top: 10px;
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        flex-direction: column;

        .detail {
          display: flex;
          flex-direction: column;

          .its {
            height: 74px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .price {
              font-size: 16px;
              color: #333333;
              font-weight: 600;
            }

            .left {
              display: flex;
              flex-direction: column;
              gap: 8px;

              .name {
                color: #333333;
                font-size: 15px;
                font-weight: 400;
              }

              .time {
                color: #808080;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }

        .header {
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
            color: #333333;
            font-size: 16px;
            font-weight: 600;
          }

          .total {
            color: #808080;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }

    .el-tabs__nav-wrap::after {
      height: 1px !important;
      background-color: rgba($color: #1F1F1F, $alpha: 0.1) !important;
    }

    .el-tabs__active-bar {
      background-color: #185BE0 !important;

    }

    .el-tabs__item.is-active {
      color: #185BE0 !important;
    }

    .el-tabs__item {
      &:hover {
        color: #185BE0 !important;

      }
    }
  }

  .numList {
    margin-top: 14px;
    background: #3A76FF;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 60px;

    .item {
      width: 246px;
      height: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      //gap: 6px;
      .num {
        color: #F8F8F8;
        font-size: 32px;
        font-weight: 500;
      }

      .name {
        color: #F8F8F8;
        font-size: 12px;

      }
    }
  }

  .title {
    color: #555555;
    font-size: 16px;

  }
}
</style>
