<template>
  <div class="rightsss">
    <div class="AttachmentManagement">
      <div class="wrap">
        <div class="title">附件管理</div>
<!--        <img v-if="current===1" src="https://static.dasdt.cn/images/userCenter/attachment.png">-->
<!--        <div v-if="current===1" class="btn" @click="fujian(10)">上传附件</div>-->
        <div class="lists">
          <template v-if="flieList.length!==0">
            <div class="item" v-for="(item, index) in flieList" :key="item.id">
              <img v-if="item.name.substring(item.name.length-3,item.name.length)==='pdf'" class="icon"
                   src="https://static.dasdt.cn/images/userCenter/pdf.png">
              <img v-else class="icon" src="https://static.dasdt.cn/images/word.png">
              <div class="middle">
                <div class="name" v-show="!isInput&&indexs===''">{{ item.name }}</div>
                <el-input v-model="name" style="width: 150px;" size="small" v-show="isInput&&indexs===index"
                          @blur="blur" @keyup.enter.native="blur" placeholder="请输入简历名称"></el-input>
                <div class="time">{{ item.createTimeFormat }} 更新</div>
              </div>
              <el-popover
                  placement="bottom"
                  width="180"
                  trigger="hover">
                <div class="attachmentpopover">
                  <div class="item" @click="preview(item.path.url)">
                    <div class="name">预览</div>
                  </div>
                  <div class="item" @click="rename(item, index)">
                    <div class="name">重命名</div>
                  </div>
                  <div class="item" @click="download(item.path.url)">
                    <div class="name">下载</div>
                  </div>
                  <div class="item" @click="deleteFuj(item.dv)">
                    <div class="name">删除</div>
                  </div>
                </div>
                <img class="gengduo" slot="reference" src="https://static.dasdt.cn/images/userCenter/gengduo.png">
              </el-popover>
            </div>
          </template>
          <el-empty v-else></el-empty>
        </div>
        <div class="upload" @click="shangchuanfujian">上传附件</div>
      </div>
    </div>
    <div class="operation">
      <div class="operationWrap">
        <div class="item" @click="xuanze(0)">
          <img src="https://static.dasdt.cn/images/userCenter/Toppings.png">
          <span>在线简历</span>
        </div>
<!--        <div class="item" @click="xuanze(10)">-->
<!--          <img src="https://static.dasdt.cn/images/userCenter/jianli.png">-->
<!--          <span>附件简历</span>-->
<!--        </div>-->
        <div class="item" @click="xuanze(10)">
          <img src="https://static.dasdt.cn/images/userCenter/Topping.png">
          <span>简历模版</span>
        </div>
        <div class="item" @click="xuanze(1)">
          <img src="https://static.dasdt.cn/images/userCenter/mainshi.png">
          <span>投递记录</span>
        </div>
<!--        <div class="item" @click="xuanze(9)" style="margin-top: 20px">-->
<!--          <img src="https://static.dasdt.cn/images/userCenter/integral.png">-->
<!--          <span>我的积分</span>-->
<!--        </div>-->
      </div>

    </div>
    <div class="operation" style="padding: 16px" >
      <div class="heads">
        <span class="name">隐私设置</span>
        <div class="rightShezhi" @click="goShezhi">
          <img src="https://static.dasdt.cn/images/userCenter/shezhi.png">
          <span>设置</span>
        </div>
      </div>
      <div class="lis">
        <div class="ite">
          <div class="ts">简历状态</div>
          <div class="ts" v-if="!$store.getters?.userInfo?.isClose">简历公开</div>
          <div class="ts" v-else>仅投递公司可见</div>
        </div>
        <div class="ite">
          <div class="ts">屏蔽企业</div>
          <div class="ts">已屏蔽 <span>{{$store.getters?.userInfo?.blacklistComment.length}}</span> 个</div>
        </div>
      </div>
    </div>
    <el-dialog
        title="上传附件简历"
        :visible.sync="dialogVisible"
        width="521px"
        :modal-append-to-body='false'
        :before-close="handleClose">
      <div class="dialogwrap">
        <el-upload
            action="aaa"
            drag
            class="avatar-uploader"
            :show-file-list="false"
            :http-request="uploadHttpRequest"
            accept=".pdf, .docx"
            :before-upload="beforeAvatarUpload"
        >
          <img src="https://static.dasdt.cn/images/file.png">
          <div class="title">点击上传或将文件拽至此处</div>
          <div class="desc">支持上传Word、PDF格式，文件大小不超过20M</div>
        </el-upload>
        <div class="prompt">
          <div class="titles">温馨提示</div>
          <li class="tishi">当你主动投简历,发简历及同意招聘方向你索要简历时, 附件会随在线简历一同发送给招聘方</li>
          <li class="tishi">其他情况下招聘方不会看到你的附件</li>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        title="提示"
        :modal-append-to-body='false'
        :visible.sync="dialogVisible2"
        width="400px"
        :before-close="handleClose2">
      <div class="tishis">
        <img src="https://static.dasdt.cn/images/userCenter/tishi.png">
        <div class="desc">确认删除该简历附件吗？</div>
        <div class="btns">
          <div class="left" @click="dialogVisible2 = false">取消</div>
          <div class="right" @click="submit">确认删除</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        title=""
        :modal-append-to-body='false'
        :visible.sync="docShow"
        width="1000px"
        :before-close="handleClose3">
      <div v-if="fileType === 'pdf'" style="margin-top: 20px;min-height: 600px">
        <VueOfficePdf
            :src="url"
            style="height: 100%"
        />
      </div>
      <div v-else style="margin-top: 20px;min-height: 600px">
        <VueOfficeDocx
            :src="url"
            style="height: 100%"
        />
      </div>
    </el-dialog>
    <el-dialog
        :modal-append-to-body='false'
        title="简历上传成功!可对该简历内容进行自动解析"
        :visible.sync="docShow2"
        width="1000px"
        :before-close="handleClose3">
      <div class="jianliWrap">
        <div class="jianli">
          <div v-if="fileType === 'pdf'">
            <VueOfficePdf
                :src="previewResume?.path?.url"
                style="height: 100%"
            />
          </div>
          <div v-else>
            <VueOfficeDocx
                :src="previewResume?.path?.url"
                style="height: 100%"
            />
          </div>
        </div>
        <div class="fujianqueren">
          <div class="title">附件确认</div>
          <div class="infos">
            <div class="item">
              <div class="key">附件名称：</div>
              <div class="key">{{fileInfo?.name}}</div>
            </div>
            <div class="item">
              <div class="key">附件大小：</div>
              <div class="key">{{Math.round(fileInfo?.size/1024)}}kb</div>
            </div>
            <div class="item">
              <div class="key">附件格式：</div>
              <div class="key">{{fileInfo?.name?.split('.')[1]}}</div>
            </div>
          </div>
<!--          <div class="desc">投简历时, 可能会将附件简历一并转发</div>-->
          <div class="btns" style="margin-top: 20px">
            <div class="left" @click="ChooseAgain">重新选择</div>
            <div class="right" @click="determineAdd">开始解析</div>
          </div>
        </div>

      </div>

    </el-dialog>
    <el-dialog
        :title="titles"
        :modal-append-to-body='false'
        :visible.sync="docShow3"
        width="396px"
        :before-close="handleClose4">
      <div class="shibiewancheng">
<!--        <div class="jindutiao"></div>-->
        <el-progress :percentage="percentage" color="#3A76FF"></el-progress>

        <div class="desc" v-if="percentage === 100">附件简历识别完成</div>
        <div class="desc" v-else>识别进度：{{percentage}}%</div>
        <div class="chakan" @click="quchakan" v-if="percentage === 100">去查看</div>
      </div>

    </el-dialog>
  </div>
</template>
<script>
import {resumeFile, resumeParse, updateresumeFile, uploadResumeFile} from "@/api/position";
import {deleteResumeFile, resumeFiles} from "@/api/currency";
import VueOfficePdf from '@vue-office/pdf'
import VueOfficeDocx from '@vue-office/docx'

export default {
  components: {
    VueOfficePdf,
    VueOfficeDocx
  },
  props: {
    openFu: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    openFu(val) {
      console.log(val)
      this.dialogVisible = true

    }
  },
  data() {
    return {
      titles: '简历附件识别中',
      percentage:0,
      docShow3: false,
      current1: 1,
      docShow2: false,
      isInput: false,
      dialogVisible2: false,
      current: 1,
      dialogVisible: false,
      pagination: {
        number: 1,
        limit: 30
      },
      flieList: [],
      dv: '',
      name: '',
      rennameDV: '',
      docShow: false,
      url: '',
      indexs: '',
      isSubmit: false,
      fileType: '',
      previewResume: {},
      files: {},
      fileInfo: {}
    }
  },
  created() {
    this.resumeFile()
  },
  methods: {
    goShezhi() {
      this.$router.push('/Privacy')
    },
    shangchuanfujian() {
      if(this.flieList.length>=3) {
        this.$message({
          message: '附件数量不能超过3个',
          type: 'warning'
        })
        return
      }
      this.dialogVisible = true
    },
    handleClose4() {
      this.docShow3 = false
    },
    quchakan() {
      this.$emit('option', 0)
      this.$router.go(0)
    },
    ChooseAgain() {
      if(this.flieList.length>=3) {
        this.$message({
          message: '附件数量不能超过3个',
          type: 'warning'
        })
        return
      }
      this.docShow2 = false
      this.dialogVisible = true
    },
    async determineAdd() {
      const res = await resumeParse(this.files)
      if (res.code === 'A00000') {
        this.$message({
          message: '添加成功',
          type: 'success'
        })

        this.docShow2 = false
        this.docShow3 = true
        this.caoZuopercentage()
      }
    },
    caoZuopercentage() {
      let timer = setInterval(() => {
        if(this.percentage<100) {
          this.percentage += 1
        } else {
          this.titles = '简历附件识别完成'
          clearInterval(timer)
        }
      },50)
    },
    handleClose3() {
      this.docShow = false
      this.docShow2 = false
    },
    preview(url) {
      console.log(url)
      this.fileType = url.substr(-3, 3)
      console.log(this.fileType)
      // this.url = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(url)}`
      this.url = url
      this.docShow = true

    },
    rename(item, index) {
      console.log(index)
      this.indexs = index
      this.rennameDV = item.dv
      this.name = item.name
      this.isInput = true
    },
    async blur() {
      if (!this.isSubmit) {
        const {data} = await updateresumeFile({name: this.name}, this.rennameDV)
        console.log(data)
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.resumeFile()
        this.isSubmit = true
        this.isInput = false
        if (!this.isInput) {
          setTimeout(() => {
            this.isSubmit = false
          }, 1500)
        }
        this.indexs = ''

      }
    },
    download(url) {
      window.location.href = url
      // window.open(url)

    },
    handleClose2() {
      this.dialogVisible2 = false
    },
    // 简历附件列表
    async resumeFile() {
      const {data} = await resumeFiles(this.pagination)
      this.flieList = data.reverse()
    },
    fujian(el) {
      this.current = el

    },
    xuanze(el) {
      this.current = el
      this.$emit('option', el)
    },
    handleClose() {
      this.dialogVisible = false
    },
    uploadHttpRequest() {
    },
    async beforeAvatarUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ['pdf', 'docx'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          message: "文件必须为 pdf,docx类型",
          type: "error",
        });
        return false;
      }
      console.log(file.size / 1024 / 1024)
      if (file.size / 1024 / 1024 > 20) {
        this.$message({
          message: "上传文件大小不得大于20MB!",
          type: "error",
        });
        return false;
      }
      let formData = new FormData();
      formData.append("file", file);
      this.fileInfo = file
      const res = await uploadResumeFile(formData)
      if(res.code === 'A00000') {
        await resumeFile({
          name: file.name,
          path: res.data[0],
          // url: data[0].url
        })
        this.files = res.data[0]
        this.$message({
          message: '上传成功',
          type: 'success'
        })
        // await this.discernResume()
        const res2 = await resumeFiles(this.pagination)

        console.log(res2.data)
        this.flieList = res2.data
        this.previewResume = res2.data[res2.data.length - 1]
        this.fileType = this.previewResume.path.url.substr(-3, 3)
        console.log(this.fileInfo)
        // this.fileInfo.size = this.fileInfo.size / 1024 + 'kb'
        // this.fileInfo.type = this.fileInfo.type.split('/')[1]
        this.dialogVisible = false
        this.docShow2 = true
      }else{
        this.$message({
          message: res.msg,
          type: 'error'
        })
      }

    },
    deleteFuj(dv) {
      this.dv = dv
      this.dialogVisible2 = true
      console.log(dv)
    },
    async submit() {
      const {data} = await deleteResumeFile(this.dv)
      console.log(data)
      this.$message({
        message: '删除成功',
        type: 'success'
      })
      await this.resumeFile()

      this.dialogVisible2 = false
    }
  }
}
</script>


<style lang="scss">
.el-progress{
  width: 253px;
  .el-progress-bar{
    padding-right: 0 !important;
    height: 10px ;
    .el-progress-bar__outer{
      height: 100% !important;
    }
  }
  .el-progress__text{
    display: none !important;
  }
}
.tongbuxinxi {

  .btnss {
    margin-top: 16px;
    margin-bottom: 26px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    .box2 {
      cursor: pointer;
      background: #3A76FF;
      width: 122px;
      height: 38px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
    }

    .box1 {
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #E6E6E6;
      width: 122px;
      height: 38px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #808080;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .wrap{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .item{
      padding: 12px;
      width: 100%;
      background: #F6F6F6;
      border-radius: 8px;
      .content{
        .font1{
          color: #555555;
          font-size: 14px;
          line-height: 20px;
        }
        .head{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .name{
            font-size: 16px;
            color: #333333;
            font-weight: 500;
          }
          .time{
            color: #808080;
            font-size: 13px;
            line-height: 16px;
            display: flex;
            align-items: center;
            img{
              width: 8px;
              height: 20px;
            }
          }
        }
      }
      .border{
        margin: 14px 0;
        border-bottom: 1px solid #C3C3C3;
      }
      .btns{
        display: flex;
        align-items: center;
        gap: 14px;
        .its{
          cursor: pointer;
          width: 122px;
          height: 38px;
          border-radius: 100px;
          border: 1px solid #3A76FF;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #3A76FF;
          font-size: 14px;
        }
      }
    }
  }
  .desc{
    color: #808080;
    font-size: 14px;
    margin-bottom: 25px;
    margin-top: 10px;
  }
  .title{
    color: #333333;
    font-weight: 500;
    font-size: 18px;
    span{
      color: #3A76FF;
    }
  }
}
.docx-wrapper>section.docx{
  width: 600px !important;
}
.tongbujianli {
  .btns {
    margin-top: 16px;
    margin-bottom: 26px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    .box2 {
      cursor: pointer;
      background: #3A76FF;
      width: 122px;
      height: 38px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
    }

    .box1 {
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #E6E6E6;
      width: 122px;
      height: 38px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #808080;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .editWrap {
    padding: 20px;
    margin-top: 12px;
    background: #F6F6F6;
    border-radius: 8px;

    .sexWrap {
      margin: 22px 0;
      display: flex;
      flex-direction: column;

      .title {
        color: #555555;
        font-size: 14px;
        font-weight: 400;
      }

      .cardList {
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 10px;

        .current {
          border: 1px solid #3A76FF !important;
          background: rgba($color: #3A76FF, $alpha: 0.1) !important;
        }

        .item {
          cursor: pointer;
          background: #E6E6E6;
          border-radius: 8px;
          width: 195.5px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #808080;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }


    .el-select {
      width: 400px;
    }

    .el-input__inner {
      border: 1px solid #E6E6E6 !important;
      border-radius: 8px !important;
    }

    .el-form-item__label {
      line-height: 0 !important;
      color: #555555 !important;
      font-weight: 400 !important;
    }
  }

  .desc {
    color: #808080;
    font-size: 14px;
  }
}
.shibiewancheng{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 25px;
  .jindutiao{
    background: #3A76FF;
    border-radius: 10px;
    width: 309px;
    height: 10px;
  }
  .desc{
    color: #808080;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 14px;
  }
  .chakan{
    cursor: pointer;
    width: 254px;
    height: 38px;
    border-radius: 100px;
    background: #3A76FF;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

  }
}
.jianliWrap {
  margin-top: 20px;
  display: flex;
  gap: 20px;

  .fujianqueren {
    padding: 16px;
    height: 220px;
    border: 1px solid #fff;
    box-shadow: 0 0 10px 0 #0000000F;
    border-radius: 8px;

    .desc {
      color: #ADADAD;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .btns {
      display: flex;
      align-items: center;
      gap: 10px;

      .left {
        border-radius: 100px;
        color: #3A76FF;
        font-size: 14px;
        cursor: pointer;
        border: 1px solid #3A76FF;
        width: 122px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .right {
        color: #fff;
        border-radius: 100px;
        font-size: 14px;
        cursor: pointer;
        background: #3A76FF;
        width: 122px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }

    .infos {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .item {
        display: flex;

        .key {
          color: #555555;
          font-size: 14px;

        }
      }
    }

    .title {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 22px;

    }
  }

  .jianli {
    background: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    box-shadow: 0 0 10px 0 #0000000F;
    width: 615px;
    min-height: 600px;

    canvas {
      width: 600px !important;
    }
  }
}

.attachmentpopover {
  display: flex;
  flex-direction: column;


  .item:not(:last-child) {
    border-bottom: 1px solid #E6E6E6;
  }

  .item {
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      color: #333333;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.vue-office-pdf-wrapper {
  background: #fff !important;
}

.vue-office-docx {
  background: #fff !important;

}

.rightsss {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .el-dialog .el-dialog__body {
    overflow: hidden;
  }

  .tishis {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 80px;
      height: 80px;
    }

    .desc {
      color: #333333;
      font-size: 20px;
      font-weight: 500;
      margin-top: 16px;
      margin-bottom: 50px;
    }

    .btns {
      display: flex;
      align-items: center;
      gap: 10px;

      .left {
        cursor: pointer;
        width: 175px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: 1px solid #ADADAD;
        border-radius: 100px;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
      }

      .right {
        cursor: pointer;
        width: 175px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #3A76FF;
        border-radius: 100px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .el-upload {
    display: flex !important;
    justify-content: center !important;
  }

  .dialogwrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    .prompt {
      padding: 12px;
      background: #F6F6F6;
      border-radius: 8px;
      margin-top: 20px;
      margin-bottom: 22px;

      .titles {
        color: #3A76FF;
        font-size: 14px;
      }

      li {
        margin-top: 12px;

        color: #555555;
        line-height: 18px;
      }
    }

    .el-upload-dragger {
      width: 470px;
      height: 158px;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }


    .avatar-uploader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 60px;
        height: 60px;
      }

      .title {
        color: #333333;
        font-size: 20px;
        font-weight: 500;
        margin: 16px 0;
      }

      .desc {
        color: #808080;
        font-size: 14px;

      }
    }
  }

  .advertisement1 {
    width: 286px;
    height: 114px;
    border-radius: 8px;

  }

  .operation {
    background: #ffffff;
    border-radius: 8px;
    .lis{
      margin-top: 28px;
      display: flex;
      flex-direction: column;
      gap: 28px;
      .ite{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ts{
          color: #333333;
          font-size: 16px;
        }
      }
    }
    .heads{
      display: flex;
      justify-content: space-between;
      .name{
        color: #333333;
        font-size: 18px;
        font-weight: 600;
      }
      .rightShezhi{
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 3px;
        img{
          width: 14px;
          height: 14px;
        }
        span{
          color: #3A76FF;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }

    .operationWrap {
      padding: 16px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .item {
        cursor: pointer;
        width: 84.33px;
        height: 56px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        img {
          width: 32px;
          height: 32px;
        }

        span {
          color: #333333;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .AttachmentManagement {
    .el-empty{
      padding: 20px 0;
    }
    .el-empty__image{
      width: 80px;
      height: 80px;
    }

    background: #ffffff;
    border-radius: 8px;
    width: 286px;
    //height: 249px;

    .wrap {
      padding: 16px;

      .upload {
        margin-top: 10px;
        height: 44px;
        background: #3A76FF;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }

      .lists {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .icon {
            width: 30px;
            height: 30px;
          }

          .gengduo {
            cursor: pointer;
            width: 24px;
            height: 24px;
          }

          .middle {
            margin-left: 8px;
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 4px;

            .el-input__inner {
              border-radius: 8px;
            }

            .name {
              color: #333333;
              font-size: 14px;
              font-weight: 500;
            }

            .time {
              color: #808080;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }

      .title {
        color: #333333;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 22px;
      }

      img {
        height: 129px;
        width: 254px;
      }

      .btn {
        cursor: pointer;
        height: 38px;
        border-radius: 100px;
        background: #3A76FF;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
</style>
