<template>
  <div class="InterviewLists">
    <div class="nav" v-if="statuss !==3">
      <div class="title">
        <span v-if="statuss === 1">已投递</span>
        <span v-if="statuss === 2">待面试</span>
        <span v-if="statuss === 4">足迹</span>{{ totalCount }}
      </div>
      <!--      <div v-if="statuss === 4" style="color: #3A76FF;font-size: 14px;cursor: pointer">清除所有足迹</div>-->
      <div class="screenWrap" v-if="statuss === 1 || statuss === 2">
        <div class="item">
          <div class="name">状态</div>
          <el-select v-model="value" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <!--        <div class="item">-->
        <!--          <div class="name">时间</div>-->
        <!--          <el-select v-model="value2" placeholder="请选择">-->
        <!--            <el-option-->
        <!--                v-for="item in options2"-->
        <!--                :key="item.value"-->
        <!--                :label="item.label"-->
        <!--                :value="item.value">-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--        </div>-->
      </div>
    </div>
    <template v-if="list.length!==0" v-loading="loading"
              element-loading-text="加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="transparent">
      <div class="list" v-if="statuss === 1 || statuss === 2">
        <div class="item" v-for="(item, index) in list" :key="index" @click="openDialog(item, 1)">
          <div class="top">
            <div class="left" style="min-width: 400px">
              <div class="nameOrPrice">
                <div class="name">{{ item?.job?.job }}</div>
                <div class="price">{{item?.job?.salaryNote}}</div>
              </div>
              <div class="infoList">
                <div class="itemss">
                  <img src="https://static.dasdt.cn/images/adress3.png">
                  <span>{{ item?.job?.cityName }}·{{ item?.job?.areaName }}</span>
                </div>
                <div class="itemss">
                  <img src="https://static.dasdt.cn/images/education.png">
                  <span>{{ item?.job?.education }}</span>
                </div>
                <div class="itemss">
                  <img src="https://static.dasdt.cn/images/experience.png">
                  <span>{{ item?.job?.jobExp }}</span>
                </div>
              </div>
            </div>
            <div class="enterpriseInfo">
              <img style="border-radius: 6.67px" :src="item?.company?.logo?.url" alt="">
              <div class="right">
                <div class="name">{{ item?.company?.name }}</div>
                <div style="display: flex">
                  <div class="tag" v-for="items in item?.company?.tags" :key="items">{{ items }}<span>·</span></div>
                </div>
              </div>
            </div>
            <div class="status" style="margin-right: 5px;background: #FAAD1426;color: #FAAD14" v-if="item.job?.state === 'CLOSED' || item.job?.state === 'UNPUBLISH'">
              <span >停止招聘</span>

            </div>
            <div class="status" v-if="statuss === 1 || statuss === 2">
              <span v-if="item.status === 'PENDING'" style="background: #60C40926;color: #60C409">已投递</span>
              <span v-if="item.status === 'SCREENING'">初筛</span>
              <span v-if="item.status === 'EVALUATION'">测评</span>
              <span v-if="item.status === 'INTERVIEW' &&(item.viewStatus=='VIEWREFUSE'||item.receiptStatus == 'VIEWREFUSE')">面试取消</span>
              <span v-else-if="item.status === 'INTERVIEW'" style="background: #F6783026;color: #F67830">面试 / 第{{item.round}}轮</span>
<!--              <span v-if="item.status === 'REEXAM'">面试复试</span>-->
              <span v-if="item.status === 'RECRUITMENT'" style="background: #FAAD1426;color: #FAAD14">正式录用</span>
              <span v-if="item.status === 'ELIMINATE'" style="background: #FFEDED;color: #FF4D4F">不合适</span>
              <span v-if="item.status === 'CANCEL'">取消投递</span>
              <span v-if="item.status === 'CANCELINTERVIEW'">取消面试</span>

            </div>
            <!--            <div style="color: #808080;font-size: 14px;cursor: pointer" v-if="statuss === 4"-->
            <!--                 @click.stop="dialogVisible3 = true">删除-->
            <!--            </div>-->
            <!--            <div class="status2" v-if="statuss === 2">-->
            <!--              初试-->
            <!--            </div>-->
          </div>
          <div class="borders" v-if="statuss !== 4"></div>
          <div class="bottom" v-if="statuss !== 4">
            <div class="timer"><span v-if="item.deliverTimeFormat">投递时间：{{ item.deliverTimeFormat }}</span></div>
            <div class="btns">
<!--              <div v-if="statuss === 2" class="detail" @click.stop="cancel(item.dv)">取消面试</div>-->
              <div v-if="statuss === 2 && item.viewStatus != 'VIEWREFUSE' && item.receiptStatus != 'VIEWREFUSE'" class="detail" @click.stop="interviewConfirm(item, 'VIEWREFUSE')">取消面试</div>
              <div class="detail" @click.stop="openDetails(item)">查看详情</div>
<!--              <div v-if="statuss === 2 && item.viewStatus != 'VIEWREFUSE' && item.receiptStatus != 'VIEWREFUSE'" class="btn1" @click.stop="interviewConfirm(item, 'VIEWCOMPLETE')">面试结束</div>-->
            </div>
          </div>
        </div>
      </div>
    </template>
    <el-empty v-else description="暂无数据"></el-empty>

    <div>

    </div>
    <div class="list" v-if="statuss === 4">
      <div v-loading="loading"
           element-loading-text="加载中"
           element-loading-spinner="el-icon-loading"
           element-loading-background="transparent"
           class="item" v-for="(item, index) in list" :key="index" @click="openDialog(item, 4)">
        <div class="top">
          <div class="left" style="min-width: 400px">
            <div class="nameOrPrice">
              <div class="name shenglule20">{{ item?.job }}</div>
              <div class="price">{{item?.salaryNote}}</div>
            </div>
            <div class="infoList">
              <div class="itemss">
                <img src="https://static.dasdt.cn/images/adress3.png">
                <span>{{ item?.cityName }}</span>
              </div>
              <div class="itemss">
                <img src="https://static.dasdt.cn/images/education.png">
                <span>{{ item?.education }}</span>
              </div>
              <div class="itemss">
                <img src="https://static.dasdt.cn/images/experience.png">
                <span>{{ item?.jobExp }}</span>
              </div>
            </div>
          </div>
          <div class="enterpriseInfo">
            <img style="border-radius: 6.67px" :src="item?.logo?.url" alt="">
            <div class="right">
              <div class="name">{{ item?.companyName }}</div>
              <div style="display: flex">
                <div class="tag" v-for="items in item?.firmTagList" :key="items">{{ items }}<span>·</span></div>
              </div>
            </div>
          </div>
          <div class="status" style="margin-right: 5px;background: #FAAD1426;color: #FAAD14" v-if="item.job?.state === 'CLOSED' || item.job?.state === 'UNPUBLISH'">
            <span >停止招聘</span>

          </div>
          <div class="status" v-if="statuss === 1">
            <span v-if="item.status === 'PENDING'" style="background: #60C40926;color: #60C409">已投递</span>
            <span v-if="item.status === 'ELIMINATE'" style="background: #FFEDED;color: #FF4D4F">不合适</span>
            <span v-if="item.status === 'INTERVIEW'" style="background: #FAAD1426;color: #FAAD14">面试[第{{item.round}}轮]</span>

          </div>
          <!--          <div style="color: #808080;font-size: 14px;cursor: pointer" v-if="statuss === 4"-->
          <!--               @click.stop="dialogVisible3 = true">删除-->
          <!--          </div>-->
          <div style="width: 100px"></div>
          <div class="status2" v-if="statuss === 2">
            初试
          </div>
        </div>
        <div class="borders" v-if="statuss !== 4"></div>
        <div class="bottom" v-if="statuss !== 4">
          <div class="timer"><span v-if="item.deliverTimeFormat">投递时间：{{ item.deliverTimeFormat }}</span></div>
          <div class="btns">
            <div class="detail" @click.stop="openDetails(item)">查看详情</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
          background
          layout="prev, pager, next, total"
          :total="totalCount"
          :current-page="pagination.number"
          :page-size="5"
          @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
        :modal-append-to-body='false'
        :visible.sync="dialogVisible"
        width="905px"
        :before-close="handleClose">
      <div class="detailss" slot="footer">
        <div v-if="statuss === 1 || statuss === 2">
          <div class="titles" style="margin-top: 0px">岗位描述</div>
          <div class="tagList">
            <div class="item" v-for="item in jobDetails.job?.firmTagList" :key="item">{{ item }}</div>
          </div>
          <div class="responsibilityWrap">
            <div class="responsibility">岗位职责：</div>
            <div class="responsibility" style="white-space: pre-wrap;">{{ jobDetails?.job?.duty }}</div>
          </div>
          <div class="responsibilityWrap">
            <div class="responsibility">任职要求：</div>
            <div class="responsibility" style="white-space: pre-wrap;">{{ jobDetails?.job?.required }}</div>
          </div>
          <div class="borderss"></div>
          <div class="titles" style="margin-top: 20px">公司信息</div>
          <div class="companyInfo">
            <img style="border-radius: 6.67px" :src="jobDetails?.company?.logo?.url">
            <div class="right" style="cursor: pointer" @click.stop="goDetails">
              <div class="name">{{ jobDetails?.company?.name }}</div>
              <div style="display: flex">
                <div class="desc" v-for="item in jobDetails?.job?.firmTagList" :key="item">{{ item }}<span>·</span>
                </div>
              </div>
            </div>
          </div>
          <div class="borderss"></div>
          <div class="titles" style="margin-top: 20px">工作地址</div>
          <div class="adresss">
            <div class="item">
              <img src="https://static.dasdt.cn/images/adress2.png">
              <span class="adressShengLue">{{ jobDetails?.job?.address }}</span>
            </div>
            <div class="item">
              <img style="margin-right: 2px" src="https://static.dasdt.cn/images/phone2.png">
              <span>联系电话：{{ jobDetails?.company?.phone }}</span>
            </div>
          </div>
        </div>
        <div v-if="statuss === 4">
          <div class="titles" style="margin-top: 0px">岗位描述</div>
          <div class="tagList">
            <div class="item" v-for="item in jobDetails.firmTagList" :key="item">{{ item }}</div>
          </div>
          <div class="responsibilityWrap">
            <div class="responsibility">岗位职责：</div>
            <div class="responsibility" style="white-space: pre-wrap;">{{ jobDetails?.duty }}</div>
          </div>
          <div class="responsibilityWrap">
            <div class="responsibility">任职要求：</div>
            <div class="responsibility" style="white-space: pre-wrap;">{{ jobDetails?.required }}</div>
          </div>
          <div class="borderss"></div>
          <div class="titles" style="margin-top: 20px">公司信息</div>
          <div class="companyInfo">
            <img style="border-radius:6.67px" :src="jobDetails?.company?.logo?.url">
            <div class="right" style="cursor: pointer" @click.stop="goDetails">
              <div class="name">{{ jobDetails?.company?.name }}</div>
              <div style="display: flex">
                <div class="desc" v-for="item in jobDetails?.firmTagList" :key="item">{{ item }}<span>·</span></div>
              </div>
            </div>
          </div>
          <div class="borderss"></div>
          <div class="titles" style="margin-top: 20px">工作地址</div>
          <div class="adresss">
            <div class="item">
              <img src="https://static.dasdt.cn/images/adress2.png">
              <span class="adressShengLue">{{ jobDetails?.job?.address }}</span>
            </div>
            <div class="item">
              <img style="margin-right: 2px" src="https://static.dasdt.cn/images/phone2.png">
              <span>联系电话：{{ jobDetails?.company?.phone }}</span>
            </div>
          </div>
        </div>

        <div style="height: 300px;width: 100%;margin-top: 16px">
          <MapContainer :info="jobDetails?.company"/>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        :modal-append-to-body='false'
        :visible.sync="dialogVisible2"
        width="485px"
        title="面试详情"
        :before-close="handleClose2">
      <div class="InterviewDetalis">
        <div class="table">
          <div class="item">
            <div class="title">面试轮次</div>
            <div class="value">第{{ jobDetails?.round }}轮</div>
          </div>
          <div class="item">
            <div class="title">面试时间</div>
            <div class="value">{{ jobDetails?.interviewTimeFormat }}</div>
          </div>
          <div class="item">
            <div class="title">面试官</div>
            <div class="value" style="margin-right: 5px" v-for="item in jobDetails?.interviewData?.[0].interviewerComment" :key="item">{{ item }}  </div>
          </div>
          <div class="item" v-if="jobDetails?.interviewData?.[0].url">
            <div class="title">面试链接</div>
            <div class="value">{{ jobDetails?.interviewData?.[0].url }}</div>
          </div>
          <div class="item" v-else>
            <div class="title">面试地点</div>
            <div class="value">{{ jobDetails?.interviewData?.[0].interviewAddress }}</div>
          </div>
          <div class="item">
            <div class="title">联系电话</div>
            <div class="value">{{ jobDetails?.interviewData?.[0].contactPhone }}</div>
          </div>
          <div class="item" v-if="jobDetails.viewStatus != 'VIEWREFUSE' && jobDetails.receiptStatus != 'VIEWREFUSE'">
            <div class="title">面试回执状态</div>
            <div class="btn" v-if="jobDetails.viewStatus === 'VIEWDEFAULT'">待处理</div>
            <div class="btn" v-if="jobDetails.viewStatus === 'VIEWCOMPLETE'">确认完成</div>
            <div class="btn" v-if="jobDetails.viewStatus === 'VIEWREFUSE'">取消</div>
          </div>
        </div>
        <div class="btns" v-if="jobDetails.viewStatus != 'VIEWREFUSE' && jobDetails.receiptStatus != 'VIEWREFUSE'">
          <div class="box1" v-if="jobDetails.viewStatus == 'VIEWDEFAULT'" @click="interviewConfirm(jobDetails, 'VIEWREFUSE')">取消面试</div>
          <div class="box2" v-if="jobDetails.viewStatus == 'VIEWDEFAULT'" @click="interviewConfirm(jobDetails, 'VIEWCOMPLETE')">结束面试</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="dialogVisible3"
        width="400px"
        :modal-append-to-body='false'
        title="提示"
        :before-close="handleClose3">
      <div class="tishi">
        <img src="https://static.dasdt.cn/images/userCenter/tishi.png">
        <div class="desc">确认删除该条浏览记录吗？</div>
        <div class="desc2">删除浏览记录, 平台将不保留任何痕迹!</div>
        <div class="btns">
          <div class="left">取消</div>
          <div class="right">确认取消</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import MapContainer from "@/components/MapContainer/index.vue";
import {cancel, interviewConfirm, JobsDetail, pageMine, pageVO} from "@/api/position";

export default {
  components: {MapContainer},
  props: {
    statuss: {
      type: Number,
      default: 0
    }
  },
  watch: {
    statuss(newVal) {
      console.log(newVal)
      this.pagination.number = 1
      console.log(this.pagination)
      if (newVal === 1) {
        this.value = ''
        this.flag = null
        this.pageMine()
      } else if (newVal === 4) {
        this.getPageVO()
      } else if (newVal === 2) {
        this.value = 'INTERVIEW'
        this.flag = 1
        this.pageMine()
      }
    },
    value() {
      console.log(1111111111)
      this.pageMine()
    },
    pagination: {
      handler: function () {
        if (this.statuss === 1) {
          this.pageMine()
        } else if (this.statuss === 4) {
          this.list = []
          this.getPageVO()
        }
      },
      deep: true
    },
  },
  data() {
    return {
      loading: false,
      value: '',
      value2: '',
      list: [],
      options: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 'PENDING',
          label: '已投递'
        },
        {
          value: 'ELIMINATE',
          label: '不合适'
        },
        {
          value: 'INTERVIEW',
          label: '面试'
        },
        // {
        //   value: 'REEXAM',
        //   label: '面试复试'
        // }
      ],
      options2: [
        {
          value: 1,
          label: '三天内'
        }
      ],
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      pagination: {
        number: 1,
        limit: 5
      },
      totalCount: 0,
      // 职位详情
      jobDetails: {},
      flag: null
    }
  },
  created() {
    if(this.$route.query.id === '2') {
      this.value = 'INTERVIEW'
      this.flag = 1
      this.pageMine()
    }
  },
  methods: {
    async interviewConfirm(jobDetails,viewStatus) {
      let params = {}
      // if (jobDetails.status == )
      params.viewStatus = viewStatus
      params.round = jobDetails.round
      const {data} = await interviewConfirm(jobDetails.dv, params)
      console.log(data)
      this.$message.success('操作成功')
      this.dialogVisible2 = false
      // 刷新界面
      this.value = 'INTERVIEW'
      this.flag = 1
      this.pageMine()
    },
    async cancel(dv) {
      console.log(dv)
      const {data} = await cancel({}, dv)
      console.log(data)
      this.$message.success('操作成功')
      await this.pageMine()
      this.dialogVisible2 = false
    },
    async getPageVO() {
      this.list = []
      this.loading = true
      const res = await pageVO(this.pagination)
      this.list = res.data
      this.totalCount = res.totalCount
      this.loading = false
    },
    async pageMine() {

      const res = await pageMine({status: this.value, flag: this.flag, direction: [{'name':'id','direction':'DESC'}]}, this.pagination)
      this.list = res.data
      this.totalCount = res.totalCount
    },
    handleCurrentChange(el) {
      this.pagination.number = el
    },
    async openDialog(item, status) {
      if (status === 4) {
        const {data} = await JobsDetail(item.id)
        this.jobDetails = data[0]
      } else {
        console.log(item)
        this.jobDetails = item
      }
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    // 跳转企业详情
    goDetails() {
      this.$router.push(`/enterpriseDetails?id=${this.jobDetails?.company?.id}`)
    },
    openDetails(item) {
      console.log(item)
      this.jobDetails = item
      if(this.statuss === 2) {
        this.dialogVisible2 = true
      }else if(this.statuss === 1) {
        this.dialogVisible = true
      }

    },
    handleClose2() {
      this.dialogVisible2 = false
    },
    handleClose3() {
      this.dialogVisible3 = false
    },
  }
}
</script>


<style lang="scss">
.InterviewLists {
  .el-dialog__footer {
    text-align: left !important;
    overflow-y: auto;
  }

  .tishi {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 80px;
      height: 80px;
    }

    .desc {
      color: #333333;
      font-size: 20px;
      font-weight: 500;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .desc2 {
      margin-bottom: 50px;
      color: #808080;
      font-size: 14px;
    }

    .btns {
      display: flex;
      align-items: center;
      gap: 10px;

      .left {
        cursor: pointer;
        width: 175px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: 1px solid #ADADAD;
        border-radius: 100px;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
      }

      .right {
        cursor: pointer;
        width: 175px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #3A76FF;
        border-radius: 100px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .InterviewDetalis {
    padding-bottom: 20px;

    .btns {
      margin-top: 20px;
      display: flex;
      align-items: center;
      gap: 10px;

      .box1 {
        border: 1px solid #ADADAD;
        cursor: pointer;
        width: 214px;
        height: 44px;
        border-radius: 100px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
      }

      .box2 {
        cursor: pointer;
        width: 214px;
        height: 44px;
        border-radius: 100px;
        background: #3A76FF;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .table {
      display: flex;
      flex-direction: column;

      .item {
        border-bottom: 1px solid #E3E4E7;
        height: 64px;
        display: flex;
        align-items: center;

        .title {
          width: 178px;
          color: #808080;
          font-size: 16px;
          font-weight: 400;
        }

        .value {
          color: #333333;
          font-size: 16px;
          font-weight: 400;
        }

        .btn {
          padding: 6px 8px;
          background: rgba($color: #000000, $alpha: 0.04);
          border-radius: 2px;
          color: rgba($color: #000000, $alpha: 0.45);
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .detailss {
    border-radius: 8px;
    padding: 16px;
    background: #fff;

    .ViewMores {
      margin: 32px auto;
      width: 200px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #185BE0;
      color: #185BE0;
      font-size: 14px;
      font-weight: 400;
      border-radius: 4px;
      cursor: pointer;
    }

    .adresss {
      display: flex;
      align-items: center;

      .item {
        width: 50%;
        display: flex;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          color: #555555;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .companyInfo {
      display: flex;
      align-items: center;
      gap: 6px;

      img {
        width: 44px;
        height: 44px;
      }

      .right {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .name {
          color: #333333;
          font-size: 16px;
          font-width: 500;
        }

        .desc {
          color: #808080;
          font-size: 14px;
          display: flex;

          span {
            display: none;
          }
        }

        .desc:not(:last-child) {
          span {
            display: block !important;
          }
        }
      }
    }

    .borderss {
      margin-top: 20px;
      border-bottom: 1px solid #E6E6E6;
    }

    .responsibilityWrap {
      margin-top: 20px;

      .responsibility {
        color: #333333;
        font-size: 14px;
        line-height: 26px;
        font-width: 400;
      }
    }

    .titles {
      margin-bottom: 16px;
      margin-top: 32px;
      color: #141414;
      font-size: 18px;
      font-width: 500;

    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }

  .list {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .item {
      cursor: pointer;
      background: #ffffff;
      border-radius: 8px;
      padding: 16px;

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btns {
          display: flex;
          align-items: center;
          gap: 30px;

          .btn1 {
            cursor: pointer;
            color: #3A76FF;
            font-size: 14px;
            font-weight: 400;
          }

          .detail {
            cursor: pointer;
            color: #808080;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .timer {
          color: #808080;
          font-size: 14px;
          font-weight: 400;
        }

      }

      .borders {
        margin: 14px 0;
        border: 1px solid rgba($color: #E6E6E6CC, $alpha: 0.8);
      }

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .status2 {
          background: rgba($color: #F67830, $alpha: 0.15);
          border-radius: 0 8px 0 8px;
          padding: 4px 10px 4px 10px;
          color: #F67830;
          font-size: 14px;
          font-weight: 400;
        }

        .status3 {
          background: rgba($color: #FAAD1426, $alpha: 0.15);
          border-radius: 0 8px 0 8px;
          padding: 4px 10px 4px 10px;
          color: #FAAD1426;
          font-size: 14px;
          font-weight: 400;
        }

        .status {
          background: #F8F8F8;
          border-radius: 0 8px 0 8px;
          padding: 4px 10px 4px 10px;
          color: #ADADAD;
          font-size: 14px;
          font-weight: 400;
        }

        .enterpriseInfo {
          display: flex;
          align-items: center;
          gap: 6px;
          flex: 1;
          //margin-left: 50px;

          img {
            width: 40px;
            height: 40px;
          }

          .right {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .name {
              font-size: 16px;
              color: #282828;
              font-weight: 500;
            }

            .tag {
              color: #808080;
              font-size: 14px;
              line-height: 16px;
              display: flex;

              span {
                display: none;
              }
            }

            .tag:not(:last-child) {
              span {
                display: block !important;
              }
            }
          }
        }

        .left {
          .infoList {
            margin-top: 10px;
            display: flex;
            align-items: center;
            gap: 24px;

            .itemss {
              display: flex;
              align-items: center;
              gap: 6px;

              img {
                width: 16px;
                height: 16px;
              }

              span {
                color: rgba($color: #000000, $alpha: 0.65);
                font-size: 14px;
                font-width: 400;
              }
            }
          }

          .nameOrPrice {
            display: flex;
            align-items: center;
            gap: 14px;

            .name {
              color: #141414;
              font-size: 16px;
              font-weight: 500;
            }

            .price {
              color: #FD5056;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }

      }
    }
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .screenWrap {
      display: flex;
      align-items: center;
      gap: 12px;

      .item {
        display: flex;
        align-items: center;
        gap: 6px;

        .name {
          color: #555555;
          font-size: 14px;
          font-weight: 400;
        }

        .el-select {
          height: 32px !important;
          width: 126px !important;
        }

        .el-input__suffix {
          height: 37px;
        }

        .el-input__inner {
          height: 32px !important;
          line-height: 32px !important;
        }
      }
    }

    .title {
      color: #555555;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
</style>
