<template>
  <div class="userInfos">
    <div class="userInfoWrap" v-if="isShow">
      <img class="head" :src="getFace(form)">
      <div class="info">
        <div class="top">
          <div class="name">{{ this.$store.getters.userInfo?.name ? this.$store.getters.userInfo?.name : '-' }}</div>
          <div class="update" @click="update">
            <img src="https://static.dasdt.cn/images/userCenter/update.png">
            <span>编辑</span>
          </div>
        </div>
        <div class="wrap">
          <div class="item" style="width: 104px">
            <img src="https://static.dasdt.cn/images/userCenter/experience.png">
            <span
                v-if="this.$store.getters.userInfo?.workExperience">{{
                this.$store.getters.userInfo.workExperience
              }}年工作经验</span>
            <span v-else>工作经验</span>
          </div>
          <div class="item">
            <img src="https://static.dasdt.cn/images/userCenter/educational.png">
            <span>{{
                this.$store.getters.userInfo?.theDegreeOfEducation ? this.$store.getters.userInfo.theDegreeOfEducation : '学历'
              }}</span>
          </div>
          <div class="item">
            <img src="https://static.dasdt.cn/images/userCenter/status.png">
            <span v-if="this.$store.getters.userInfo.jobStatus === 'ALWAYSDUTY'">请编辑</span>
            <span v-if="this.$store.getters.userInfo.jobStatus === 'CONSIDERING'">离职-随时到岗</span>
            <span v-if="this.$store.getters.userInfo.jobStatus === 'DEFAULTJOB'">在职-月内到岗</span>
            <span v-if="this.$store.getters.userInfo.jobStatus === 'MONTHSEMPLOYMENT'">在职-考虑机会</span>
            <span v-if="this.$store.getters.userInfo.jobStatus === 'ONJOB'">在职-暂不考虑</span>
          </div>
        </div>
        <div class="wrap">
          <div class="item" style="width: 104px">
            <img src="https://static.dasdt.cn/images/userCenter/phone.png">
            <span>{{ this.$store.getters.userInfo?.phone ? this.$store.getters.userInfo?.phone : '手机号' }}</span>
          </div>
          <div class="item" >
            <img src="https://static.dasdt.cn/images/userCenter/weixin.png">
            <span>{{ this.$store.getters.userInfo?.wechat ? this.$store.getters.userInfo?.wechat : '微信号' }}</span>
          </div>
        </div>
      </div>
      <div class="numList">
        <div class="item" @click="select2(1)" :class="{current2: current2 === 1}">
          <div class="num">{{ person?.deliver }}</div>
          <div class="name">已投递</div>
        </div>
        <div class="item" @click="select2(2)" :class="{current2: current2 === 2}">
          <div class="num">{{ person?.interview }}</div>
          <div class="name">待面试</div>
        </div>
        <div class="item" @click="select2(3)" :class="{current2: current2 === 3}">
          <div class="num">{{ person?.collect }}</div>
          <div class="name">收藏</div>
        </div>
        <div class="item" @click="select2(4)" :class="{current2: current2 === 4}">
          <div class="num">{{ person?.browses }}</div>
          <div class="name">足迹</div>
        </div>
      </div>
    </div>
    <div v-else class="edit">
      <div class="title" :style="{color: desireShow?'#F56C6C':'','font-weight':desireShow?'700':'500'}">编辑个人信息</div>
      <div class="editWrap">
        <el-form ref="form" :rules="rules" label-position="top" :model="form" label-width="80px">
          <div style="display: flex;align-items: center;justify-content: space-between">
            <div>
              <el-form-item label="姓名" prop="name">
                <el-input style="width: 400px" placeholder="请输入姓名" v-model="form.name"></el-input>
              </el-form-item>
              <div class="sexWrap">
                <el-form-item label="性别" prop="gender">
                  <div class="cardList">
                    <div class="item" @click="celect('男')" :class="{current: form.gender === '男'}">男</div>
                    <div class="item" @click="celect('女')" :class="{current: form.gender === '女'}">女</div>
                  </div>
                </el-form-item>

              </div>
            </div>
            <div class="upload" style="width: 217px;">
              <div
                  class="avatar-uploader"
                  @click="uploadPicture('vsdvs')"
              >
                <img :src="getFace(form)"/>
              </div>
              <div class="titles" @click="uploadPicture('vsdvs')">选择头像</div>
              <div class="desc">只支持JPG、PNG格式<br/>建议使用一寸证件照</div>
            </div>
          </div>
          <div style="display: flex;align-items: center;gap: 26px">
            <el-form-item label="出生年月" prop="birthDay">
              <el-date-picker type="date" placeholder="请选择" v-model="form.birthDay" format="yyyy 年 MM 月 dd 日"
                              value-format="yyyy-MM-dd"
                              style="width: 400px;"></el-date-picker>
            </el-form-item>
            <el-form-item label="开始工作时间" >
              <el-date-picker type="month" placeholder="请选择" v-model="form.workTime" format="yyyy 年 MM 月"
                              value-format="yyyy-MM"
                              style="width: 400px;"/>
<!--              <el-input style="width: 350px" minlength="1" maxlength="2" placeholder="请输入工作年限" v-model="form.workExperience" onkeyup="this.value=this.value.match(/\d+/)"-->
<!--                        onafterpaste="this.value=this.value.match(/\d+/)"></el-input><a style="margin-left: 4px">年</a>-->
            </el-form-item>
          </div>
          <div style="display: flex;align-items: center;gap: 26px">
            <el-form-item label="电话" prop="phone">
              <el-input style="width: 400px" placeholder="请输入电话" maxlength="11" v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="微信号" prop="wechat">
              <el-input style="width: 400px" placeholder="请输入微信号" v-model="form.wechat"></el-input>
            </el-form-item>
          </div>
          <div style="display: flex;align-items: center;gap: 26px">
            <el-form-item label="邮箱" prop="email">
              <el-input style="width: 400px" placeholder="请输入邮箱" v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item label="当前求职状态" >
              <el-select v-model="form.jobStatus" placeholder="请选择求职状态">
                <el-option label="离职-随时到岗" value="CONSIDERING"></el-option>
                <el-option label="在职-月内到岗" value="DEFAULTJOB"></el-option>
                <el-option label="在职-考虑机会" value="MONTHSEMPLOYMENT"></el-option>
                <el-option label="在职-暂不考虑" value="ONJOB"></el-option>
              </el-select>
            </el-form-item>
          </div>
<!--          <el-form-item label="最高学历" >-->
<!--            <el-select v-model="form.theDegreeOfEducation" :disabled="form?.theDegreeOfEducation?.length>0" placeholder="请选择最高学历">-->
<!--              <el-option label="博士" value="博士"></el-option>-->
<!--              <el-option label="硕士" value="硕士"></el-option>-->
<!--              <el-option label="本科" value="本科"></el-option>-->
<!--              <el-option label="大专" value="大专"></el-option>-->

<!--            </el-select>-->
<!--          </el-form-item>-->
        </el-form>
        <div class="btns">
          <div class="box1" @click="quxiao('form')">取消</div>
          <div class="box2" @click="submit('form')">提交保存</div>
        </div>
      </div>
    </div>
    <el-dialog
        :modal-append-to-body='false'
        :visible.sync="cropperModel"
        width="800px"
        center
    >
      <cropper-image
          :Name="cropperName"
          @uploadImgSuccess = "handleUploadSuccess"
          ref="child">
      </cropper-image>
    </el-dialog>
  </div>
</template>
<script>
import CropperImage from "@/components/CropperImage/index";

import { getCurrentResume, personal, updateMember, uploadFaceImg} from "@/api/currency";

export default {
  components: {CropperImage},
  props: {
    option: {
      type: Number,
      default: 0
    },
    quxiaos: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      //裁切图片参数
      cropperName:'',
      imgName: '',
      imgVisible: false,
      cropperModel: false,
      isShow: true,
      form: {
        ...this.$store.getters.userInfo
      },
      current: 1,
      current2: 0,
      imageUrl: '',
      dv: '',
      person: {},
      workExperience: '',
      rules: {
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
          {min: 1, max: 10, message: '长度在 1 到 11 个字符', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '请输入电话', trigger: 'blur'},
          {
            pattern:
                /^1(1[0-9]|3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: 'blur'
          }
        ],
        email: [
          {required: true, message: '请输入邮箱', trigger: 'blur'},
          {
            pattern:
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "请输入正确的邮箱",
            trigger: 'blur'
          }
        ],
        wechat: [
          {
            pattern:
                /^[a-zA-Z][a-zA-Z0-9_-]{5,19}$/,
            message: "请输入正确的微信号",
            trigger: 'blur'
          }
        ],
        gender: [
          {required: true, message: '请选择性别', trigger: 'blur'}
        ],
        theDegreeOfEducation: [
          {required: true, message: '请选择最高学历', trigger: 'blur'}
        ],
        jobStatus: [
          {required: true, message: '请选择求职状态', trigger: 'blur'}
        ],
        birthDay: [
          {required: true, message: '请选择出生年月', trigger: 'blur'}
        ],
        workTime: [
          {required: true, message: '请选择开始工作时间', trigger: 'blur'}
        ]
      }
    }
  },
  watch: {
    option(val) {
      this.current2 = val
    },
    quxiaos(){
      this.getPersonal()

    }
  },
  created() {
    if (this.$route.query.id) {
      this.current2 = Number(this.$route.query.id)
    }
    if(!this.form.gender ||this.form.gender === '') {
      this.form.gender = ''
    }
    this.getPersonal()
    if(this.isDeletion&&this.desireShow){
      this.isShow = !this.isShow
    }
  },
  computed: {
    tabMemberUpdateDTO() {
      return {
        ...this.form
      }
    },
    isDeletion() {
      return !!this.$route.query.deletion;
    },
    desireShow() {
      return !this.form.birthDay || !this.form.email;
    }
  },
  methods: {
    quxiao(formName) {
      this.$refs[formName].resetFields();
      this.$emit('option', 0)
      this.isShow = !this.isShow
    },
    getFace(member) {
      if (member && member.gender === '男') {
        return member.faceImg && member.faceImg.url ? member.faceImg.url : 'https://static.dasdt.cn/images/nan.png'
      } else if (member && member.gender === '女') {
        return member.faceImg && member.faceImg.url ? member.faceImg.url : 'https://static.dasdt.cn/images/nv.png'
      } else {
        return member.faceImg && member.faceImg.url ? member.faceImg.url : 'https://static.dasdt.cn/images/deful.png'
      }
    },
    uploadPicture(name){
      this.cropperName = name;
      this.cropperModel = true;
    },
    async getPersonal() {
      const {data} = await personal()
      this.person = data[0]
    },
    async getCurrentResume() {
      const {data} = await getCurrentResume()
      await this.$store.commit('userInfo', data[0])
      this.dv = data[0].dv
    },
    getYearsSince(year, month) {
      // 创建给定日期的Date对象
      const givenDate = new Date(year, month - 1); // 注意月份是从0开始的

      // 创建当前日期的Date对象
      const currentDate = new Date();

      // 计算年份差
      let yearsSince = currentDate.getFullYear() - givenDate.getFullYear();

      // 如果当前日期还没有到达给定日期，需要减去一年
      if (currentDate.getMonth() < givenDate.getMonth() ||
          (currentDate.getMonth() === givenDate.getMonth() && currentDate.getDate() < givenDate.getDate())) {
        yearsSince--;
      }

      // 返回结果
      return yearsSince;
    },
    async submit(formName) {

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // if(this.workExperience) {
          //   this.form.workTime = this.workExperience
          //   let time = this.workExperience.split('-')
          //   this.form.workExperience = this.getYearsSince(time[0], time[1])
          // }
          await this.getCurrentResume()
          const res = await updateMember(this.tabMemberUpdateDTO, this.dv)
          console.log(res)
          await this.getCurrentResume()
          this.isShow = !this.isShow
        }
      })
    },
    uploadHttpRequest() {
    },
    getYearsAgoToday(yearsBack) {
      // 创建一个当前日期的副本
      const today = new Date();

      // 设置日期为几年前
      const yearsAgo = new Date();
      yearsAgo.setFullYear(today.getFullYear() - yearsBack);

      // 获取年月日
      const year = yearsAgo.getFullYear();
      const month = String(yearsAgo.getMonth() + 1).padStart(2, '0'); // 月份是从0开始的，所以+1
      // const day = String(yearsAgo.getDate()).padStart(2, '0');

      // 返回结果
      return `${year}-${month}`;
    },

    update() {
      // if(this.form.workExperience) {
      //   if(this.form.workExperience.toString().length<3){
      //     this.workExperience = this.getYearsAgoToday(this.form.workExperience)
      //   }
      // }

      this.isShow = false
    },
    celect(el) {
      this.form.gender = el
    },
    select2(el) {
      this.$emit('option', el)
      this.current2 = el
    },
    async beforeAvatarUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["jpg", "png", "bmp", "gif"];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          message: "文件必须为 jpg,png,bmp,gif 类型",
          type: "error",
        });
        return false;
      }

      if (file.size / 1024 / 1024 > 20) {
        this.$message({
          message: "上传文件大小不得大于20MB!",
          type: "error",
        });
        return false;
      }
      console.log(file)
      let formData = new FormData();
      formData.append("file", file);
      const {data} = await uploadFaceImg(formData)
      console.log(data)
      this.form.faceImg = data[0]
    },
    //图片上传成功后
    handleUploadSuccess (data){
      console.log(data)
      this.form.faceImg = data

      this.cropperModel = false;
    }

  }
}
</script>


<style lang="scss">
.userInfos {
  background: #ffffff;
  border-radius: 8px;
  width: 900px;

  .upload {
    display: flex;
    flex-direction: column;
    align-items: center;

    .titles {
      cursor: pointer;
      margin: 8px 0;
      color: #3A76FF;
      font-size: 14px;
      font-weight: 400;
    }

    .desc {
      color: #808080;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .avatar-uploader {
      img {
        width: 76px;
        height: 76px;
        border-radius: 50%;
      }
    }

    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      width: 76px;
      height: 76px;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 76px;
      height: 76px;
      line-height: 76px;
      text-align: center;
    }

    .avatar {
      width: 76px;
      height: 76px;
      display: block;
    }
  }

  .edit {
    padding: 20px;

    .editWrap {
      padding: 20px;
      margin-top: 12px;
      background: #F6F6F6;
      border-radius: 8px;
      //height: 574px;

      .sexWrap {
        margin: 22px 0;
        display: flex;
        flex-direction: column;

        .title {
          color: #555555;
          font-size: 14px;
          font-weight: 400;
        }

        .cardList {
          margin-top: 10px;
          display: flex;
          align-items: center;
          gap: 10px;

          .current {
            border: 1px solid #3A76FF !important;
            background: rgba($color: #3A76FF, $alpha: 0.1) !important;
          }

          .item {
            cursor: pointer;
            background: #E6E6E6;
            border-radius: 8px;
            width: 195.5px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #808080;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;

        .box2 {
          cursor: pointer;
          background: #3A76FF;
          width: 122px;
          height: 38px;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-size: 14px;
          font-weight: 400;
        }

        .box1 {
          cursor: pointer;
          background: #ffffff;
          border: 1px solid #E6E6E6;
          width: 122px;
          height: 38px;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #808080;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .el-select {
        width: 400px;
      }

      .el-input__inner {
        border: 1px solid #E6E6E6 !important;
        border-radius: 8px !important;
      }

      .el-form-item__label {
        line-height: 0 !important;
        color: #555555 !important;
        font-weight: 400 !important;
      }
    }

    .title {
      color: #333333;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .userInfoWrap {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .numList {
      display: flex;
      align-items: center;

      .current2 {
        background: rgba($color: #3A76FF, $alpha: 0.12);

        .num {
          color: #3A76FF !important;
        }

        .name {
          color: #3A76FF !important;
        }
      }

      .item {
        padding: 13px 0;
        border-radius: 6px;
        width: 72.5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        gap: 8px;

        .num {
          color: #33343C;
          font-size: 28px;
          font-weight: 700;
        }

        .name {
          color: #808080;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex: 1;
      margin-left: 12px;

      .wrap {
        display: flex;
        align-items: center;
        gap: 30px;

        .item {
          display: flex;
          align-items: center;
          gap: 4px;

          img {
            width: 14px;
            height: 14px;
          }

          span {
            color: #555555;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .top {
        display: flex;
        align-items: center;
        gap: 4px;

        .name {
          color: #000000;
          font-size: 24px;
          font-weight: 500;
        }

        .update {
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 1px;

          img {
            width: 13.5px;
            height: 13.5px;
          }

          span {
            font-size: 14px;
            color: #67686F;
            font-weight: 400;
          }
        }
      }
    }

    .head {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
  }
}
</style>
