<template>
   <div class="Topping">
     <div class="poster">
       <div class="title">建立置顶</div>
       <div class="list">
         <div class="item">
           <img src="https://static.dasdt.cn/images/userCenter/Topping2.png">
           <div class="name">邀约不断</div>
           <div class="desc">坐等海量邀约</div>
         </div>
         <div class="item">
           <img src="https://static.dasdt.cn/images/userCenter/Topping2.png">
           <div class="name">曝光翻倍</div>
           <div class="desc">获得更多企业来访</div>
         </div>
         <div class="item">
           <img src="https://static.dasdt.cn/images/userCenter/Topping2.png">
           <div class="name">提升活跃</div>
           <div class="desc">HR青睐的概率飙升</div>
         </div>
       </div>
       <div class="timer">简历置顶时间将于2023-10-23 22:00:00到期</div>
     </div>
     <div class="ServicePackage">
       <div class="name">选择服务套餐</div>
       <div class="desc">简历置顶曝光可提升257%</div>
       <div class="ServicePackageList">
         <div class="item" v-for="(item, index) in ServicePackageList" :key="index">
           <div class="Days">10天</div>
           <div class="integral">100<span>积分</span></div>
           <div class="btn">购买</div>
         </div>
       </div>
     </div>
   </div>
</template>
<script>
export default {
  data() {
    return{
      activities: [
          {
        content: '出发',
        timestamp: '10:30',
        size: 'large',
        type: 'primary',
        icon: 'el-icon-more'
      }, {
        content: '恰中午饭',
        timestamp: '12：00',
        // color: '#0bbd87',
        size: 'large',
        type: 'primary',
        icon: 'el-icon-more',

      }, {
        content: '长兴岛毛毛水泥厂(悲伤逆流成河取景地)',
        timestamp: '14：00',
        size: 'large',
        type: 'primary',
        icon: 'el-icon-more',

      }, {
        content: '上海正泰农业有限公司(搁浅游轮)',
        timestamp: '15：30',
        size: 'large',
        type: 'primary',
        icon: 'el-icon-more',
      },
        {
          content: '横沙岛(春晓农庄 开车上轮渡过去)',
          timestamp: '16：30',
          size: 'large',
          type: 'primary',
          icon: 'el-icon-more',
        },
        {
          content: '崇明岛(中华鲟梦园)',
          timestamp: '18：30',
          size: 'large',
          type: 'primary',
          icon: 'el-icon-more',
        },
        {
          content: '恰晚饭',
          timestamp: '20：30',
          size: 'large',
          type: 'primary',
          icon: 'el-icon-more',
        },
      ],
      ServicePackageList: [
        {},
        {},
        {},
        {},
        {},
        {},
      ]
    }
  }
}
</script>


<style scoped lang="scss">
.Topping{
   display: flex;
  flex-direction: column;
  background: #fff;
  .ServicePackage{
    border-radius: 8px;
    background: #fff;
    padding: 20px;
    .name{
      color: #333333;
      font-size: 18px;
      font-weight: 500;
    }
    .desc{
      margin-top: 10px;
      margin-bottom: 20px;
      color: #808080;
      font-size: 14px;
      font-weight: 400;
    }
    .ServicePackageList{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 18px;
      .item{
        border: 1px solid #ADADAD;
        width: 274px;
        height: 232px;
        border-radius: 8px;
        display: flex;
        gap: 12px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover{
          border: 1px solid #FF4455 !important;
          .btn{
            background: #FF4455;
            color: #fff !important;
          }
        }
        .Days{
          color: #333333;
          font-size: 30px;
          font-weight: 500;
        }
        .integral{
          color: #FF4455;
          font-size: 30px;
          font-weight: 600;
          line-height: 30px;
          span{
            font-size: 14px;
          }
        }
        .btn{
          border: 1px solid #FF4457;
          width: 200px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          color: #FF4457;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
  .poster{
    background: url("https://static.dasdt.cn/images/userCenter/Topping1.png") no-repeat center;
    background-size: 100% 100%;
    width: 100%;
    height: 307px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      font-size: 24px;
      font-weight: 600;
      color: #FBCEA8;
      margin: 30px 0 30px 0;
    }
    .timer{
      margin-top: 25px;
      padding: 10px 16px;
      background: #28315B;
      border-radius: 100px;
      color: #FFDDBC;
      font-size: 14px;
      font-weight: 400;

    }
    .list{
      display: flex;
      align-items: center;
      gap: 36px;
      .item{
        width: 262.33px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
          width: 60px;
          height: 60px;
        }
        .name{
          margin-top: 16px;
          margin-bottom: 12px;
          color: #FFDDBC;
          font-size: 18px;
          font-weight: 500;
        }
        .desc{
          font-size: 14px;
          font-weight: 400;
          color: #E6E6E6;
        }
      }
    }

  }

}
</style>
