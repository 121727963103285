<template>
  <div class="attachment">
    <div class="title">简历模版</div>
    <div class="desc">完善在线简历, 可从模版下载导出附件简历</div>
    <div class="contents">
      <!--      <div id="wordView" v-html="wordText" />-->

      <div class="left" v-for="(item, index) in imgList" :key="item.id">
        <img :src="item.img.url">
        <div class="adb">模版 <span v-if="index===0">一</span><span v-if="index===1">二</span></div>
        <div class="Mask">
          <div class="btn">
            <div class="btnWrap">
<!--              <div class="btn-item" @click="setResumeTemplate(item.id)">-->
<!--                <span>选择模版</span>-->
<!--              </div>-->
              <div class="btn-items" @click="getUserResume(item.id)">
                <span>使用此模板导出简历</span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getUserResume, resumeTemplate, setResumeTemplate} from "@/api/currency";
import mammoth from "mammoth";
export default {
  data() {
    return {
      file: '',
      imgList: [],
      wordURL: '',
      wordText: '',
      id: ''
    }
  },
  created() {
    // this.getUserResume()
    this.resumeTemplate()
  },
  methods: {
    async setResumeTemplate(id) {
      const {data} = await setResumeTemplate(id)
      console.log(data)
      this.$message({
        message: '设置成功',
        type: 'success'
      })
    },
    async resumeTemplate() {
      const {data} = await resumeTemplate()
      console.log(data)
      this.imgList = data

    },
    async getUserResume(id) {
      if(Object.keys(this.$store.getters.userInfo).length>0){
        const info = this.$store.getters.userInfo
        if(info.customJobTag?.length === 0 || !info.birthDay || !info.email || info.working?.length === 0 || info.project?.length === 0 || info.cateIdsComment?.length === 0) {
          this.$message({
            message: '请先完善个人简历',
            type: 'warning'
          });
          this.$router.push('/PersonalCenter?deletion=1')
          return
        }
      }
      const {data} = await getUserResume(id)
      this.wordURL = data[0]
      console.log(this.wordURL)
      var a = document.createElement('a') // 创建一个<a></a>标签
      a.href = this.wordURL         //重点（如测试发现下载文件不存在/找不到，检查路径）
      a.download = this.wordURL         // 设置下载文件文件名
      a.style.display = 'none'           // 隐藏a标签
      document.body.appendChild(a)       // 将a标签追加到文档对象中
      a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      a.remove()
      // window.open(this.wordURL)
    },
    getWordText() {
      const xhr = new XMLHttpRequest();
      xhr.open("get", this.wordURL, true);
      xhr.responseType = "arraybuffer";
      xhr.onload = () => {
        if (xhr.status === 200) {
          mammoth.convertToHtml({arrayBuffer: new Uint8Array(xhr.response)}).then((resultObject) => {
            this.$nextTick(() => {
              this.wordText = resultObject.value;
            });
          });
        }
      };
      xhr.send();
    }
  }
}
</script>


<style scoped lang="scss">
.attachment {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  min-height: 810px;
  width: 900px;

  .contents {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    .left {
      border-radius: 8px;
      border: 1px solid #fff;
      box-shadow: 0 0 10px 0 rgba($color: #000000, $alpha: 0.1);
      width: 420px;
      height: 554px;
      background: linear-gradient(to bottom right, #E2EBFF, #FFFFFF);
      position: relative;

      .Mask {
        .btn {
          display: none;
        }
      }

      &:hover {
        .Mask {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, .5);
          display: flex;
          justify-content: center;
          align-items: end;
          border-radius: 8px;

          .btn {
            display: block !important;

            .btnWrap {
              margin-bottom: 70px;
              display: flex;
              align-items: center;
              gap: 10px;

              .btn-item {
                background: #fff;
                width: 170px;
                height: 44px;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                color: #333333;
                cursor: pointer;
              }

              .btn-items {
                background: #3A76FF;
                width: 200px;
                height: 44px;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                color: #fff;
                cursor: pointer;
              }
            }


          }
        }
      }
    }


    .adb {
      position: absolute;
      bottom: 0;
      left: 16px;
      background: rgba(255, 255, 255, .7);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      width: 388px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 58px;
      color: #2D313D;
      font-size: 18px;
    }

    img {
      margin: 16px;
      width: 388px;
      height: 522px;
      border-radius: 8px;

    }
  }
}

.title {
  color: #333333;
  font-size: 18px;
  font-weight: 500;
}

.desc {
  margin-top: 12px;
  color: #ADADAD;
  font-size: 14px;
  font-weight: 400;
}


</style>
