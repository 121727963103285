<template>
  <div class="resume">
    <div class="resumeWrap">
      <img @click="openShiBie" class="perfect" src="https://static.dasdt.cn/images/userCenter/perfect.png">

      <!--       工作经历-->
      <div class="advantage" v-if="experienceShowShow">
        <div class="header">
          <div class="title" :style="{color:form.working?.length===0?'#F56C6C':'','font-weight':form.working?.length===0?'700':'500'}">工作经历(必填)</div>
          <div class="btn" @click="experienceShowShow = false">
            <img src="https://static.dasdt.cn/images/userCenter/add.png">
            <span>添加</span>
          </div>
        </div>
        <div class="content">
          <div class="workExperienceList">
            <div class="item" v-for="(item, index) in form.working" :key="index">
              <div class="head">
                <div class="name">{{ item.company }}</div>
                <div class="timer">
                  <div class="timerWrap" v-if="item?.startDay">
                    <div class="time">{{ item?.startDay.slice(0,7) }} - {{ item.endDay?item.endDay.slice(0,7): '至今' }}</div>
                    <img src="https://static.dasdt.cn/images/userCenter/right.png">
                  </div>
                </div>

                <div class="operation">
                  <div class="operationWrap">
                    <div class="item" @click="eventUpdate(item, 'work')">
                      <img src="https://static.dasdt.cn/images/userCenter/update2.png">
                      <span>编辑</span>
                    </div>
                    <div class="item" @click="deleteExperience(item.dv, 'experience')">
                      <img src="https://static.dasdt.cn/images/userCenter/delete.png">
                      <span>删除</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="font_1">{{ item.position }} {{ item.department }}</div>
              <div class="font_1" style="white-space: pre-wrap;">{{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="advantage" v-else>
        <div class="header">
          <div class="title">添加工作经历</div>

        </div>
        <div class="content">
          <div class="editWrap">
            <el-form :rules="rules2" ref="ruleForm" label-position="top" :model="workExperience" label-width="80px">
              <div style="display: flex;align-items: center;justify-content: space-between;gap: 20px">
                <el-form-item label="公司名称" prop="company">
                  <el-input style="width: 400px" placeholder="请输入公司名称" minlength="4" maxlength="20"
                            v-model="workExperience.company"></el-input>
                </el-form-item>
                <el-form-item label="所属行业" >
                  <div style="width: 400px;height: 40px;background: #fff;border: 1px solid #E6E6E6;border-radius: 8px;padding: 0 15px;cursor: pointer;" @click="openSuoshuhangye">
                    {{workExperience.industry}}
                  </div>
<!--                  <el-input style="width: 400px" placeholder="请输入所属行业"-->
<!--                            v-model="workExperience.industry"></el-input>-->

                </el-form-item>
              </div>
              <div style="display: flex;align-items: center;gap: 26px">
                <el-form-item label="所属部门" prop="department">
                  <el-input style="width: 400px" placeholder="请输入所属部门" minlength="2" maxlength="15"
                            v-model="workExperience.department"></el-input>

                </el-form-item>
                <el-form-item label="职位名称" prop="position" >
                  <el-input style="width: 400px" placeholder="请输入职位名称" minlength="2" maxlength="15"
                            v-model="workExperience.position"></el-input>
                </el-form-item>
              </div>
              <el-form-item label="在职时间" prop="timeFrame">
                <div style="display: flex;gap: 10px">
                  <el-date-picker
                      style="width: 188px;"
                      v-model="value2"
                      format="yyyy 年 MM 月"
                      value-format="yyyy-MM"
                      type="month"
                      :picker-options="endDatePicker2"
                      placeholder="请选择">
                  </el-date-picker> -
                  <el-date-picker
                      v-model="value3"
                      :disabled="value2 === ''"
                      style="width: 188px;"
                      format="yyyy 年 MM 月"
                      ref="datePicker"
                      value-format="yyyy-MM"
                      :class="timeEndNow && !value3?'time-picker':''"
                      :placeholder="timeEndNow?'':'请选择'"
                      type="month"
                      @focus="timeEndNow = false"
                      :picker-options="{shortcuts: [
                          {text: '至今',
                            onClick(picker) {
                              now(picker)
                            }
                          }
                      ],
                      disabledDate: time => {
                        if (time.getTime() < new Date(this.value2).getTime()){
                          return true;
                        }
                        if(time.getTime() > Date.now()){
                          return true
                        }
                         return false;
                      }
                      }">
                  </el-date-picker>
                </div>

<!--                <el-date-picker placeholder="请选择" type="datetimerange" v-model="workExperience.timeFrame"-->
<!--                                format="yyyy 年 MM 月 dd 日"-->
<!--                                value-format="yyyy-MM-dd"-->
<!--                                start-placeholder="开始日期"-->
<!--                                end-placeholder="结束日期"-->
<!--                                style="width: 400px;"></el-date-picker>-->
              </el-form-item>
              <div style="display: flex;align-items: center;gap: 26px;width: 100%">
                <el-form-item label="工作内容" prop="content">
                  <el-input style="width: 828px" type="textarea" :rows="9" placeholder="请输入工作内容" minlength="5" maxlength="1024" show-word-limit
                            v-model="workExperience.content"></el-input>
                </el-form-item>
              </div>
            </el-form>
            <div class="btns">
              <div class="box1" @click="cancel('experienceShowShow')">取消</div>
              <div class="box2" @click="submit2('ruleForm')">提交保存</div>
            </div>
          </div>
        </div>
      </div>
      <!--       项目经历-->
      <div class="advantage" v-if="projectShow">
        <div class="header">
          <div class="title" :style="{color: form.project?.length===0?'#F56C6C':'','font-weight':form.project?.length===0?'700':'500'}">项目经历(必填)</div>
          <div class="btn" @click="projectShow = false">
            <img src="https://static.dasdt.cn/images/userCenter/add.png">
            <span>添加</span>
          </div>
        </div>
        <div class="content">
          <div class="workExperienceList">
            <div class="item" v-for="(item, index) in form.project" :key="index">
              <div class="head">
                <div class="name">{{ item.projectName }}</div>
                <div class="timer">
                  <div class="timerWrap" v-if="item?.startDay">
                    <div class="time">{{ item?.startDay.slice(0,7) }} - {{ item.endDay?item.endDay.slice(0,7): '至今' }}</div>
                    <img src="https://static.dasdt.cn/images/userCenter/right.png">
                  </div>
                </div>
                <div class="operation">
                  <div class="operationWrap">
                    <div class="item" @click="eventUpdate(item, 'project')">
                      <img src="https://static.dasdt.cn/images/userCenter/update2.png">
                      <span>编辑</span>
                    </div>
                    <div class="item" @click="deleteExperience(item.dv, 'project')">
                      <img src="https://static.dasdt.cn/images/userCenter/delete.png">
                      <span>删除</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="font_1">{{ item.position }}</div>
              <div class="font_1" style="white-space: pre-wrap;">{{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="advantage" v-else>
        <div class="header">
          <div class="title">添加项目经历</div>
        </div>
        <div class="content">
          <div class="editWrap">
            <el-form ref="ruleForm3" :rules="rules" label-position="top" :model="projectForm" label-width="80px">
              <div style="display: flex;align-items: center;justify-content: space-between;gap: 20px">
                <el-form-item label="项目名称" prop="projectName">
                  <el-input style="width: 400px" placeholder="请输入项目名称" maxlength="20"
                            v-model="projectForm.projectName"></el-input>
                </el-form-item>
                <el-form-item label="项目角色" prop="position">
                  <el-input style="width: 400px" placeholder="请输入项目角色" v-model="projectForm.position" maxlength="10"></el-input>
                </el-form-item>
              </div>
              <div style="display: flex;align-items: center;gap: 26px">
                <el-form-item label="项目链接">
                  <el-input style="width: 400px" placeholder="请输入项目链接" v-model="projectForm.url" maxlength="40"></el-input>
                </el-form-item>
                <el-form-item label="项目时间" prop="timeFrame">

                  <div style="display: flex;gap: 10px">
                    <el-date-picker
                        style="width: 188px;"
                        v-model="value2"
                        format="yyyy 年 MM 月"
                        value-format="yyyy-MM"
                        type="month"
                        :picker-options="endDatePicker2"
                        placeholder="请选择">
                    </el-date-picker> -
                    <el-date-picker
                        v-model="value3"
                        :disabled="value2 === ''"
                        style="width: 188px;"
                        format="yyyy 年 MM 月"
                        ref="datePicker"
                        value-format="yyyy-MM"
                        :class="timeEndNow && !value3?'time-picker':''"
                        :placeholder="timeEndNow?'':'请选择'"
                        type="month"
                        @focus="timeEndNow = false"
                        :picker-options="{shortcuts: [
                          {text: '至今',
                            onClick(picker) {
                              now(picker)
                            }
                          }
                        ],
                        disabledDate: time => {
                        if (time.getTime() < new Date(this.value2).getTime()){
                          return true;
                        }
                        if(time.getTime() > Date.now()){
                          return true
                        }
                         return false;
                      }
                        }">
                    </el-date-picker>
                  </div>

<!--                  <el-date-picker placeholder="请选择" type="datetimerange" v-model="projectForm.timeFrame"-->
<!--                                  format="yyyy 年 MM 月 dd 日"-->
<!--                                  value-format="yyyy-MM-dd"-->
<!--                                  start-placeholder="开始日期"-->
<!--                                  end-placeholder="结束日期"-->
<!--                                  style="width: 400px;"></el-date-picker>-->
                </el-form-item>
              </div>
              <div style="display: flex;align-items: center;gap: 26px;width: 100%">
                <el-form-item label="项目描述" prop="content">
                  <el-input style="width: 828px" type="textarea" :rows="9" placeholder="请输入项目描述" maxlength="1024" show-word-limit
                            v-model="projectForm.content"></el-input>
                </el-form-item>
              </div>
            </el-form>
            <div class="btns">
              <div class="box1" @click="cancel('projectShow')">取消</div>
              <div class="box2" @click="projectSubmit('ruleForm3')">提交保存</div>
            </div>
          </div>
        </div>
      </div>
      <!--       职位期望-->
      <div class="advantage" v-if="positionShow">
        <div class="header">
          <div class="title" :style="{color:form.cateIdsComment?.length===0?'#F56C6C':'','font-weight':form.cateIdsComment?.length===0?'700':'500'}">求职期望(必填)</div>
          <div class="btn" @click="eventUpdateExpect">
            <img src="https://static.dasdt.cn/images/userCenter/add.png">
            <span>编辑</span>
          </div>
        </div>
        <div class="content" style="margin-left: 11px">
          <div class="font_2">
            <span v-if="form?.cateIdsComment?.length>0">
                      <span class="qiwangzhiwei" v-for="(item,index) in form.cateIdsComment"
                            :key="index">{{ item }} <span class="xiexian">/</span></span>
                    </span>
            <span
              v-if="form?.expectSalary?.lowSalary">{{ form?.expectSalary?.lowSalary }}k - {{
              form?.expectSalary?.highSalary
            }}k</span><span v-if="form?.expectSalary?.duration"> · {{form?.expectSalary?.duration}}薪</span></div>
          <div class="font_1">{{ form.cityName }} {{ form.areaName }} <span style="margin-right: 10px" v-for="item in form?.industry" :key="item">{{ item }}</span></div>
        </div>
      </div>
      <div class="advantage" v-else>
        <div class="header">
          <div class="title">添加求职期望</div>
        </div>
        <div class="content">
          <div class="editWrap">
            <el-form ref="form" :rules="rules" label-position="top" :model="form" label-width="80px">
              <div style="display: flex;align-items: center;justify-content: space-between;gap: 20px">
                <el-form-item label="期望职位" required prop="cateIdsComment">
                  <div
                      style="height: 40px;width: 400px; border: 1px solid #E6E6E6;border-radius: 8px;background: #fff;padding: 0 15px;cursor: pointer"
                      @click="isShow = true">
                    <span v-if="form?.cateIdsComment?.length>0">
                      <span class="qiwangzhiwei" style="color: #606266" v-for="(item,index) in form.cateIdsComment"
                            :key="index">{{ item }} <span class="xiexian">/</span></span>
                    </span>
                    <span v-else style="color: #B4B9BF;font-size: 14px">请选择期望职位</span>
                  </div>
                </el-form-item>
                <el-form-item label="期望城市">
                  <el-cascader
                      placeholder="请选择期望城市"
                      v-model="cityArr"
                      :options="options"
                      :props="props"
                  />
                </el-form-item>
              </div>
              <div style="display: flex;align-items: center;gap: 26px">
                <el-form-item label="期望行业" >
                  <div style="width: 400px;height: 40px;background: #fff;border: 1px solid #E6E6E6;border-radius: 8px;padding: 0 15px;cursor: pointer;" @click="openQiwanghangye">
                    <span style="margin-right: 10px" v-for="item in form.industry" :key="item">{{item}}</span>
                  </div>
                </el-form-item>
                <el-form-item label="薪资要求(单位：k)">
                  <div style="display: flex;align-items: center;gap: 12px" >
                    <el-input style="width: 105px"
                              placeholder="请输入"
                              type="number"
                              :min="1"
                              :max="100"
                              @keyup.native="proving($event)"
                              @input="editInput($event,'plan')"
                              v-model="form.expectSalary.lowSalary"></el-input>
                    <span>至</span>
                    <el-input style="width: 105px" placeholder="请输入"
                              type="number"
                              :min="1"
                              :max="100"
                              @keyup.native="proving($event)"
                              @input="editInput2($event,'plan')"
                              v-model="form.expectSalary.highSalary"></el-input>
                    <div style="background: #000000;border-radius: 50%;width: 3px;height: 3px"></div>
                    <el-input style="width: 105px" placeholder="请输入"
                              type="number"
                              :min="1"
                              :max="100"
                              @keyup.native="proving($event)"
                              @input="editInput3($event,'plan')"
                              v-model="form.expectSalary.duration"></el-input>
                    <span>薪</span>
                  </div>


                </el-form-item>
              </div>
              <div style="display: flex;align-items: center;gap: 26px">
                <el-form-item label="职位类型" >
                  <el-select v-model="form.jobType" placeholder="请选择">
                    <el-option
                        label="实习生"
                        value="trainee">
                    </el-option>
                    <el-option
                        label="校园招聘"
                        value="college_recruitment">
                    </el-option>
                    <el-option
                        label="社会招聘"
                        value="social_recruitment">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

            </el-form>
            <div class="btns">
              <div class="box1" @click="cancel('positionShow')">取消</div>
              <div class="box2" @click="submit('positionShow')">提交保存</div>
            </div>
          </div>
        </div>
      </div>
      <!--      技能标签-->
      <div class="advantage">
        <div class="header">
          <div class="title" :style="{color: form.customJobTag?.length===0?'#F56C6C':'','font-weight':form.customJobTag?.length===0?'700':'500'}">技能标签(必填)</div>
          <div class="btn" @click="openSkill">
            <img src="https://static.dasdt.cn/images/userCenter/add.png">
            <span>添加</span>
          </div>
        </div>
        <div class="content" style="margin-left: 11px">
          <div class="skillTaglist">
            <div class="item" v-for="(item, index) in form?.customJobTag" :key="item">
              <img src="https://static.dasdt.cn/images/userCenter/cancel.png" @click="deleteSkill(index)">
              <span>{{item}}</span>
            </div>
          </div>
        </div>
      </div>
      <!--       个人优势-->
      <div class="advantage" v-if="advantageShow">
        <div class="header">
          <div class="title">个人优势</div>
          <div class="btn" @click="advantageShow = false">
            <img src="https://static.dasdt.cn/images/userCenter/update.png">
            <span>编辑</span>
          </div>
        </div>
        <div class="content" style="margin-left: 11px">
          <div class="font_1" style="white-space: pre-wrap;">{{ form.advantages }}</div>
        </div>
      </div>
      <div class="advantage" v-else>
        <div class="header">
          <div class="title">编辑个人优势</div>
        </div>
        <div class="content">
          <div class="editWrap">
            <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                maxlength="200"
                show-word-limit
                v-model="form.advantages">
            </el-input>
            <div class="btns">
              <div class="box1" @click="cancel('advantageShow')">取消</div>
              <div class="box2" @click="submit('advantageShow')">提交保存</div>
            </div>
          </div>
        </div>
      </div>
      <!--       教育经历-->
      <div class="advantage" v-if="educationShow">
        <div class="header">
          <div class="title">教育经历</div>
          <div class="btn" @click="educationShow = false">
            <img src="https://static.dasdt.cn/images/userCenter/add.png">
            <span>添加</span>
          </div>
        </div>
        <div class="content" style="display: flex;flex-direction: column;gap: 20px">
          <div class="school" v-for="(item, index) in form.education" :key="index">
            <div class="schoolWrap">
              <!--              <img src="https://static.dasdt.cn/images/userCenter/school.png">-->
              <div class="right">
                <div class="name">{{ item.school }}</div>
                <div class="desc">{{ item.professional }} {{ item.education }}</div>
              </div>
            </div>
            <div class="timer">
              <div class="timerWrap" v-if="item?.startDay">
                <div class="time">{{ item?.startDay.slice(0,7) }} - {{ item.endDay?item.endDay.slice(0,7): '至今' }}</div>
                <img src="https://static.dasdt.cn/images/userCenter/right.png">
              </div>
            </div>
            <div class="operation">
              <div class="operationWrap">
                <div class="item" @click="eventUpdate(item, 'school')">
                  <img src="https://static.dasdt.cn/images/userCenter/update2.png">
                  <span>编辑</span>
                </div>
                <div class="item" @click="deleteExperience(item.dv, 'school')">
                  <img src="https://static.dasdt.cn/images/userCenter/delete.png">
                  <span>删除</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="advantage" v-else>
        <div class="header">
          <div class="title">添加教育经历</div>
        </div>
        <div class="content">
          <div class="editWrap">
            <el-form ref="ruleForm2" label-position="top" :rules="rules" :model="EducationalExperience"
                     label-width="80px">
              <div style="display: flex;align-items: center;justify-content: space-between;gap: 20px">
                <el-form-item label="学校名称" prop="school" >
                  <el-input style="width: 400px" placeholder="请输入学校名称" maxlength="20"
                            v-model="EducationalExperience.school"></el-input>
                </el-form-item>
                <el-form-item label="学历" prop="education">
                  <el-select style="width: 400px" v-model="EducationalExperience.education" placeholder="请选择学历">
                    <el-option label="博士" value="博士"></el-option>
                    <el-option label="硕士" value="硕士"></el-option>
                    <el-option label="本科" value="本科"></el-option>
                    <el-option label="大专" value="大专"></el-option>
                  </el-select>
<!--                  <el-input style="width: 400px" placeholder="请输入学历"-->
<!--                            v-model="EducationalExperience.education"></el-input>-->
                </el-form-item>
              </div>
              <div style="display: flex;align-items: center;gap: 26px">
                <el-form-item label="专业" prop="professional" >
                  <el-input style="width: 400px" placeholder="请输入专业" maxlength="15"
                            v-model="EducationalExperience.professional"></el-input>
                </el-form-item>
                <el-form-item label="时间段" prop="timeFrame">

                  <div style="display: flex;gap: 10px">
                    <el-date-picker
                        style="width: 188px;"
                        v-model="value2"
                        format="yyyy 年 MM 月"
                        value-format="yyyy-MM"
                        type="month"
                        :picker-options="endDatePicker2"
                        placeholder="请选择">
                    </el-date-picker> -
                    <el-date-picker
                        v-model="value3"
                        :disabled="value2 === ''"
                        style="width: 188px;"
                        format="yyyy 年 MM 月"
                        ref="datePicker"
                        value-format="yyyy-MM"
                        :class="timeEndNow && !value3?'time-picker':''"
                        :placeholder="timeEndNow?'':'请选择'"
                        type="month"
                        @focus="timeEndNow = false"
                        :picker-options="{shortcuts: [
                          {text: '至今',
                            onClick(picker) {
                              now(picker)
                            }
                          }
                        ],
                        disabledDate: time => {
                        if (time.getTime() < new Date(this.value2).getTime()){
                          return true;
                        }
                        if(time.getTime() > Date.now()){
                          return true
                        }
                         return false;
                      }
                        }">
                    </el-date-picker>
                  </div>

<!--                  <el-date-picker type="datetimerange" placeholder="请选择" v-model="EducationalExperience.timeFrame"-->
<!--                                  format="yyyy 年 MM 月 dd 日"-->
<!--                                  value-format="yyyy-MM-dd"-->
<!--                                  start-placeholder="开始日期"-->
<!--                                  end-placeholder="结束日期"-->
<!--                                  style="width: 400px;"></el-date-picker>-->
                </el-form-item>
              </div>
            </el-form>
            <div class="btns">
              <div class="box1" @click="cancel('educationShow')">取消</div>
              <div class="box2" @click="educationSubmit('ruleForm2')">提交保存</div>
            </div>
          </div>
        </div>
      </div>
      <!--       自定义添加-->
      <div class="advantage" style="border: none">
        <div class="header"
             v-if="form.memberTrains?.length===0 || form.skillName?.length===0|| form.memberCreations?.length===0">
          <div class="title">自定义添加</div>
        </div>
        <div class="content">
          <div class="customList">
            <div class="item" v-if="form.memberTrains?.length===0" @click="memberTrainsShow = !memberTrainsShow">
              <span>培训经历</span>
              <img src="https://static.dasdt.cn/images/userCenter/add.png">
            </div>
            <div class="item" v-if="form.skill?.length === 0" @click="skillShow = !skillShow">
              <span>资格证书</span>
              <img src="https://static.dasdt.cn/images/userCenter/add.png">
            </div>
            <div class="item" v-if="form.memberCreations?.length===0" @click="creationShow = !creationShow">
              <span>作品案例</span>
              <img src="https://static.dasdt.cn/images/userCenter/add.png">
            </div>
          </div>
        </div>
      </div>
      <!--       培训经历-->
      <div class="advantage" v-if="form.memberTrains?.length!==0&&memberTrainsShow">
        <div class="header">
          <div class="title">培训经历</div>
          <div class="btn" @click="memberTrainsShow = false">
            <img src="https://static.dasdt.cn/images/userCenter/add.png">
            <span>添加</span>
          </div>
        </div>
        <div class="content">
          <div class="workExperienceList">
            <div class="item" v-for="(item, index) in form.memberTrains" :key="index">
              <div class="head">
                <div class="name">{{ item.name }}</div>
                <div class="timer">
                  <div class="timerWrap" v-if="item?.startDay">
                    <div class="time">{{ item?.startDay.slice(0,7) }} - {{ item.endDay?item.endDay.slice(0,7): '至今' }}</div>
                    <img src="https://static.dasdt.cn/images/userCenter/right.png">
                  </div>
                </div>
                <div class="operation">
                  <div class="operationWrap">
                    <div class="item" @click="eventUpdate(item, 'train')">
                      <img src="https://static.dasdt.cn/images/userCenter/update2.png">
                      <span>编辑</span>
                    </div>
                    <div class="item" @click="deleteExperience(item.dv, 'train')">
                      <img src="https://static.dasdt.cn/images/userCenter/delete.png">
                      <span>删除</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="font_1">{{ item.direction }}</div>
              <div class="font_1" style="white-space: pre-wrap;">{{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="advantage" v-if="!memberTrainsShow">
        <div class="header">
          <div class="title">添加培训经历</div>
        </div>
        <div class="content">
          <div class="editWrap">
            <el-form ref="ruleForm4" :rules="rules" label-position="top" :model="TrainsForm" label-width="80px">
              <div style="display: flex;align-items: center;justify-content: space-between;gap: 20px">
                <el-form-item label="培训中心名称" prop="name" >
                  <el-input style="width: 400px" placeholder="请输入培训中心名称" maxlength="20"
                            v-model="TrainsForm.name"></el-input>
                </el-form-item>
                <el-form-item label="培训方向" prop="direction">
                  <el-input style="width: 400px" placeholder="请输入培训方向" v-model="TrainsForm.direction" maxlength="20"></el-input>
                </el-form-item>
              </div>
              <div style="display: flex;align-items: center;gap: 26px">
                <el-form-item label="培训时间" prop="timeFrame">

                  <div style="display: flex;gap: 10px">
                    <el-date-picker
                        style="width: 188px;"
                        v-model="value2"
                        format="yyyy 年 MM 月"
                        value-format="yyyy-MM"
                        type="month"
                        :picker-options="endDatePicker2"
                        placeholder="请选择">
                    </el-date-picker> -
                    <el-date-picker
                        v-model="value3"
                        :disabled="value2 === ''"
                        style="width: 188px;"
                        format="yyyy 年 MM 月"
                        ref="datePicker"
                        value-format="yyyy-MM"
                        :class="timeEndNow && !value3?'time-picker':''"
                        :placeholder="timeEndNow?'':'请选择'"
                        type="month"
                        @focus="timeEndNow = false"
                        :picker-options="{shortcuts: [
                          {text: '至今',
                            onClick(picker) {
                              now(picker)
                            }
                          }
                        ],
                        disabledDate: time => {
                        if (time.getTime() < new Date(this.value2).getTime()){
                          return true;
                        }
                        if(time.getTime() > Date.now()){
                          return true
                        }
                         return false;
                      }
                        }">
                    </el-date-picker>
                  </div>

<!--                  <el-date-picker placeholder="请选择" type="datetimerange" v-model="TrainsForm.timeFrame"-->
<!--                                  format="yyyy 年 MM 月 dd 日"-->
<!--                                  value-format="yyyy-MM-dd"-->
<!--                                  start-placeholder="开始日期"-->
<!--                                  end-placeholder="结束日期"-->
<!--                                  style="width: 400px;"></el-date-picker>-->
                </el-form-item>
              </div>
              <div style="display: flex;align-items: center;gap: 26px;width: 100%">
                <el-form-item label="培训经历描述(非必填)" >
                  <el-input style="width: 828px" type="textarea" :rows="4" placeholder="请输入培训经历描述" maxlength="200" show-word-limit
                            v-model="TrainsForm.content"></el-input>
                </el-form-item>
              </div>
            </el-form>
            <div class="btns">
              <div class="box1" @click="cancel('memberTrainsShow')" >取消</div>
              <div class="box2" @click="TrainsSubmit('ruleForm4')">提交保存</div>
            </div>
          </div>
        </div>
      </div>
      <!--       资格证书-->
      <div class="advantage" v-if="form.skill.length!==0&&skillShow">
        <div class="header">
          <div class="title">资格证书</div>
          <div class="btn" @click="skillShow = false">
            <img src="https://static.dasdt.cn/images/userCenter/add.png">
            <span>添加</span>
          </div>
        </div>
        <div class="content">
          <div class="workExperienceList" style="flex-direction: row;flex-wrap: wrap">
            <div class="item" v-for="(item, index) in form.skill" :key="index">
              <div class="head" style="flex-direction: column;min-width: 200px;height: 52px">
                <div class="name" v-for="its in item.skillName" :key="its">{{ its }}</div>
                <div class="operation">
                  <div class="operationWrap">
                    <div class="item" @click="eventUpdate(item, 'skill')">
                      <img src="https://static.dasdt.cn/images/userCenter/update2.png">
                      <span>编辑</span>
                    </div>
                    <div class="item" @click="deleteExperience(item.dv, 'skill')">
                      <img src="https://static.dasdt.cn/images/userCenter/delete.png">
                      <span>删除</span>
                    </div>
                  </div>
                </div>
              </div>
<!--              <div class="font_1" v-if="item.level === 'ORDINARY'">一般</div>-->
<!--              <div class="font_1" v-if="item.level === 'ATTUNED'">良好</div>-->
<!--              <div class="font_1" v-if="item.level === 'PROFICIENCY'">精通</div>-->
<!--              <div class="font_1" v-if="item.level === 'EXPERT'">精通</div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="advantage" v-if="!skillShow">
        <div class="header">
          <div class="title">添加资格证书</div>
        </div>
        <div class="content">
          <div class="editWrap">
            <el-form ref="ruleForm5" :rules="rules" label-position="top" :model="SkillForm" label-width="80px">
              <el-form-item label="证书名称" prop="skillName">
                <el-input style="width: 400px" placeholder="请输入证书名称" maxlength="20"
                          v-model="SkillForm.skillName"></el-input>
              </el-form-item>
              <div style="color:#555555;font-size: 14px;margin-bottom: 10px">推荐证书</div>
              <div style="display: flex;align-items: center;gap: 8px;flex-wrap: wrap;width: 88%">
                <div @click="selectscertificate(item)" :style="item === SkillForm.skillName ? {color: '#3A76FF!important',border: 'none!important', background: '#3A76FF1A !important'} : {}" style="border: 1px solid #E6E6E6;padding: 9px 10px;border-radius: 4px;background: #fff;cursor: pointer;color: #1C1D24;font-size: 12px" v-for="item in certificate" :key="item">{{item}}</div>
              </div>
            </el-form>
            <div class="btns">
              <div class="box1" @click="quxiaoskill">取消</div>
              <div class="box2" @click="skillSubmit('ruleForm5')">提交保存</div>
            </div>
          </div>
        </div>
      </div>
      <!--       作品案例-->
      <div class="advantage" v-if="form.memberCreations?.length!==0&&creationShow">
        <div class="header">
          <div class="title">作品案例</div>
          <div class="btn" @click="creationShow = false">
            <img src="https://static.dasdt.cn/images/userCenter/add.png">
            <span>添加</span>
          </div>
        </div>
        <div class="content">
          <div class="workExperienceList">
            <div class="item" v-for="(item, index) in form.memberCreations" :key="index">
              <div class="head">
                <div class="name">{{ item.creationName }}</div>
                <div class="operation">
                  <div class="operationWrap">
                    <div class="item" @click="eventUpdate(item, 'creation')">
                      <img src="https://static.dasdt.cn/images/userCenter/update2.png">
                      <span>编辑</span>
                    </div>
                    <div class="item" @click="deleteExperience(item.dv, 'creation')">
                      <img src="https://static.dasdt.cn/images/userCenter/delete.png">
                      <span>删除</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="font_1">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="advantage" v-if="!creationShow">
        <div class="header">
          <div class="title">添加作品案例</div>
        </div>
        <div class="content">
          <div class="editWrap">
            <el-form ref="ruleForm6" :rules="rules" label-position="top" :model="creationForm" label-width="80px">
              <div style="display: flex;align-items: center;justify-content: space-between;gap: 20px">
                <el-form-item label="作品名称" prop="creationName">
                  <el-input style="width: 400px" placeholder="请输入作品名称" maxlength="20"
                            v-model="creationForm.creationName"></el-input>
                </el-form-item>
                <el-form-item label="作品链接">
                  <el-input style="width: 400px" placeholder="请输入作品链接" maxlength="40"
                            v-model="creationForm.url"></el-input>
                </el-form-item>
              </div>
              <el-form-item label="作品介绍" prop="content">
                <el-input type="textarea" :rows="4" placeholder="请输入作品介绍" maxlength="200" show-word-limit
                          v-model="creationForm.content"></el-input>
              </el-form-item>
            </el-form>
            <div class="btns">
              <div class="box1" @click="cancel('creationShow')">取消</div>
              <div class="box2" @click="creationSubmit('ruleForm6')">提交保存</div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <el-dialog
        :visible.sync="dialogVisible3"
        width="400px"
        :modal-append-to-body='false'
        title="提示"
        :before-close="handleClose3">
      <div class="tishi">
        <img src="https://static.dasdt.cn/images/userCenter/tishi.png">
        <div class="desc">确认删除该条{{ dialogName }}吗？</div>
        <div class="desc2">删除{{ dialogName }}, 平台将不保留任何痕迹!</div>
        <div class="btns">
          <div class="left" @click="dialogVisible3 = false">取消</div>
          <div class="right" @click="confirmDelete">确认删除</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="dialogVisible4"
        width="600px"
        :modal-append-to-body='false'
        title="添加技能标签"
        :before-close="handleClose4">
      <div class="addskill">
        <el-input v-model="skillName"  placeholder="请输入技能标签" maxlength="15"/>
        <div style="display: flex;align-items: center;gap: 8px;flex-wrap: wrap;margin-top: 20px">
          <div @click="selectsskillTag(item)" :style="groupIds2.includes(item) ? {color: '#3A76FF!important',border: 'none!important', background: '#3A76FF1A !important'} : {}" style="border: 1px solid #E6E6E6;width: 103px;height:34px;display:flex;align-items:center;justify-content:center;border-radius: 4px;background: #fff;cursor: pointer" v-for="item in skillTag" :key="item">{{item}}</div>
        </div>
        <div class="btns">
          <div class="left" @click="dialogVisible4 = false">取消</div>
          <div class="right" @click="addSkill">确认</div>
        </div>
      </div>
    </el-dialog>
    <desire v-model="isShow"/>
    <el-dialog
        lock-scroll
        :modal-append-to-body='false'
        title="行业类型"
        :visible.sync="dialogVisible"
        width="1000px"
        :before-close="handleClose2"
    >
      <div class="wrapJobIntention">
        <div class="titles">请选择公司类型</div>
        <div class="contentss">
          <div class="navigation">
            <div
                v-for="(item, index) in navList"
                :key="index"
                class="item"
                :class="{ active: item.name === activeId}"
                @click="selects2(item.name, index)"
            >
              <div class="itemWrap">
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
          <div class="tagLists">
            <div
                v-for="(its, indexs) in seMenuInfo?.nodes"
                :key="indexs"
                class="tags"
                :class="{'current': groupIds.includes(its.name)}"
                @click="selectss3(its.name)"
            >{{ its.name }}
            </div>
          </div>
        </div>

      </div>
      <div class="footers">
        <div class="left" >
          <div class="key" v-if="groupIds.length>0">已选行业：</div>
          <div class="list">
            <div class="item" v-for="(item, index) in groupIds" :key="index">
              <span class="names">{{ item }}</span>
              <img class="delets" @click="eventDelete(item)" src="https://static.dasdt.cn/images/userCenter/delets.png">
            </div>
          </div>
        </div>
        <div class="btn">
          <div v-if="groupIds.length === 0" class="right">我选好了(0/3)</div>
          <div v-if="groupIds.length >0" class="right2" @click="submit3">我选好了({{ groupIds.length }}/3)</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  addCreation,
  addMemberRrain, addMemberSkill, deleteCreation,
  deleteMemberEducation,
  deleteMemberProject, deleteMemberRrain, deleteMemberSkill,
  deleteMemberWorking,
  getCurrentResume,
  memberEducation, memberProject,
  memberWorking, updateCreation,
  updateMember, updateMemberEducation, updateMemberProject, updateMemberRrain, updateMemberSkill,
  updateMemberWorking
} from "@/api/currency";
import tree from '@/assets/tree.json'
import categoryJob from '@/assets/categoryJob.json'
import desire from "@/components/desire/index.vue";

export default {
  components: {desire},
  data() {
    return {
      skillTag: [
          'JAVA',
          'Python',
          'C++',
          'MySQL',
          'HTML',
          'VUE',
          'CSS',
          'JQuery',
          'React',
          'JavaScript',
      ],
      value2: '',
      value3: null,
      timeEndNow: false,
      groupIds: [],
      activeId: '互联网/AI',
      activeId2: '互联网',
      activeIndex: 1,

      dialogVisible: false,
      certificate: [
          '大学英语四级',
          '大学英语六级',
          'PMP项目管理认证',
          '计算机二级',
          '驾驶证C1',
          '英语专业四级',
          '程序员技术资格',
          '网络管理员',
          '网络工程师',
          '信息安全工程师',
      ],
      navList: [
        {
          name: '不限',
          nodes: [
            {
              name: '不限'
            }
          ]
        },
        {
          name: '互联网/AI',
          nodes: [
            {
              name: '互联网'
            }, {
              name: '电子商务'
            }, {
              name: '计算机软件'
            }, {
              name: '生活服务（O2O）'
            }, {
              name: '企业服务'
            }, {
              name: '医疗健康'
            }, {
              name: '游戏'
            }, {
              name: '社交网络与媒体'
            }, {
              name: '人工智能'
            }, {
              name: '云计算'
            }, {
              name: '在线教育'
            }, {
              name: '计算机服务'
            }, {
              name: '大数据'
            }, {
              name: '广告营销'
            }, {
              name: '物联网'
            }, {
              name: '新零售'
            }, {
              name: '信息安全'
            }
          ]
        },
        {
          name: '电子/通信/半导体',
          nodes: [
            {
              name: '半导体/芯片'
            }, {
              name: '电子/硬件开发'
            }, {
              name: '通信/网络设备'
            }, {
              name: '智能硬件/消费电子'
            }, {
              name: '运营商/增值服务'
            }, {
              name: '计算机硬件'
            }
          ]
        },
        {
          name: '房地产/建筑',
          nodes: [
            {
              name: '半导体/芯片'
            }, {
              name: '装修装饰'
            }, {
              name: '房屋建筑工程'
            }, {
              name: '土木工程'
            }, {
              name: '机电工程'
            }, {
              name: '物业管理'
            }, {
              name: '房地产中介/租赁'
            }, {
              name: '建筑设计'
            }, {
              name: '建筑材料'
            }, {
              name: '建筑工程咨询服务'
            }, {
              name: '房地产开发经营'
            }, {
              name: '土地与公共设施管理'
            }
          ]
        },
        {
          name: '教育培训',
          nodes: [
            {
              name: '培训/辅导机构'
            }, {
              name: '学校/学历教育'
            }, {
              name: '职业培训'
            }, {
              name: '学术/科研'
            }, {
              name: '学前教育'
            }
          ]
        },
        {
          name: '广告/传媒/文化/体育',
          nodes: [
            {
              name: '文化艺术/娱乐'
            }, {
              name: '广播/影视'
            }, {
              name: '体育'
            }, {
              name: '新闻/出版'
            }, {
              name: '广告/公关/会展'
            }
          ]
        },
        {
          name: '制造业',
          nodes: [
            {
              name: '通用设备'
            }, {
              name: '金属制品'
            }, {
              name: '化学原料/化学制品'
            }, {
              name: '印刷/包装/造纸'
            }, {
              name: '新材料'
            }, {
              name: '专用设备'
            }, {
              name: '非金属矿物制品'
            }, {
              name: '仪器仪表'
            }, {
              name: '电气机械/器材'
            }, {
              name: '橡胶/塑料制品'
            }, {
              name: '自动化设备'
            }, {
              name: '铁路/船舶/航空/航天制造'
            }, {
              name: '计算机/通信/其他电子设备'
            }, {
              name: '其他制造业'
            }
          ]
        },
        {
          name: '专业服务',
          nodes: [
            {
              name: '咨询'
            }, {
              name: '法律'
            }, {
              name: '财务/审计/税务'
            }, {
              name: '检测/认证/知识产权'
            }, {
              name: '人力资源服务'
            }, {
              name: '翻译'
            }, {
              name: '其他专业服务'
            }
          ]
        },
        {
          name: '制药/医疗',
          nodes: [
            {
              name: '医疗服务'
            }, {
              name: 'IVD'
            }, {
              name: '医美服务'
            }, {
              name: '生物/制药'
            }, {
              name: '医疗器械'
            }, {
              name: '医药批发零售'
            }, {
              name: '医疗研发外包'
            }
          ]
        },
        {
          name: '汽车',
          nodes: [
            {
              name: '新能源汽车'
            }, {
              name: '汽车后市场'
            }, {
              name: '汽车智能网联'
            }, {
              name: '汽车研发/制造'
            }, {
              name: '汽车经销商'
            }, {
              name: '汽车零部件'
            }, {
              name: '摩托车/自行车制造'
            }
          ]
        },
        {
          name: '交通运输/物流',
          nodes: [
            {
              name: '即时配送'
            }, {
              name: '同城货运'
            }, {
              name: '客运服务'
            }, {
              name: '快递'
            }, {
              name: '跨境物流'
            }, {
              name: '港口/铁路/公路/机场'
            }, {
              name: '公路物流'
            }, {
              name: '装卸搬运和仓储业'
            }
          ]
        },
        {
          name: '能源/化工/环保',
          nodes: [
            {
              name: '光伏'
            }, {
              name: '风电'
            }, {
              name: '化工'
            }, {
              name: '矿产/地质'
            }, {
              name: '储能'
            }, {
              name: '其他新能源'
            }, {
              name: '电力/热力/燃气/水利'
            }, {
              name: '采掘/冶治炼'
            }, {
              name: '动力电池'
            }, {
              name: '环保'
            }, {
              name: '石油/石化'
            }
          ]
        },
        {
          name: '金融',
          nodes: [
            {
              name: '互联网金融'
            }, {
              name: '证券/期货'
            }, {
              name: '租赁/拍卖/典当/担保'
            }, {
              name: '银行'
            }, {
              name: '基金'
            }, {
              name: '信托'
            }, {
              name: '投资/融资'
            }, {
              name: '保险'
            }, {
              name: '财富管理'
            }, {
              name: '其他金融业'
            }
          ]
        },
        {
          name: '政府/非盈利机构/其他',
          nodes: [
            {
              name: '农/林/牧/渔'
            }, {
              name: '非盈利机构'
            }, {
              name: '政府/公共事业'
            }, {
              name: '其他行业'
            }
          ]
        }
      ],
      skillName: '',
      isShow: false,
      categoryJob: categoryJob.data,
      dialogName: '',
      memberTrainsShow: true,
      creationShow: true,
      skillShow: true,
      dialogVisible3: false,
      dialogVisible4: false,
      timeFrame: [],
      props: {
        value: "name",
        label: "name",
        children: "nodes",
      },
      options: tree.data,
      cityArr: [],
      form: {
        ...this.$store.getters.userInfo,
        // expectSalary: {
        //   highSalary: '',
        //   lowSalary: ''
        // }
      },
      advantageShow: true,
      positionShow: true,
      experienceShowShow: true,
      projectShow: true,
      educationShow: true,
      // 工作经历数据
      workExperience: {
        industry: '',
        company: '',
        content: '',
        department: '',
        endDay: '',
        startDay: '',
        position: '',
        timeFrame: []
      },
      current: 1,
      dv: '',
      rules2: {
        company: [
          {required: true, message: '请输入公司名称', trigger: 'blur'},
        ],
        industry: [
            {required: true, message: '请输入所属行业', trigger: 'blur'},
        ],
        position: {
          required: true,
          message: '请输入职位名称',
          trigger: 'blur'
        },
        timeFrame: [
            {required: true, message: '请选择在职时间', trigger: 'blur'},
        ],
        content: {
          required: true,
          message: '请输入描述',
          trigger: 'blur'
        }
      },
      rules: {
        industry: [
          {required: true, message: '请输入所属行业', trigger: 'blur'},
        ],
        company: [
          {required: true, message: '请输入公司名称', trigger: 'blur'},
        ],
        content: [
          {required: true, message: '请输入描述', trigger: 'blur'},
        ],
        timeFrame: [
          {required: true, message: '请选择时间', trigger: 'blur'},
        ],
        school: [
          {required: true, message: '请输入学校名称', trigger: 'blur'},
        ],
        education: [
          {required: true, message: '请选择学历', trigger: 'change'},
        ],
        professional: [
          {required: true, message: '请输入专业', trigger: 'blur'},
        ],
        projectName: [
          {required: true, message: '请输入项目名称', trigger: 'blur'},
        ],
        position: [
          // {required: true, message: '请输入项目角色', trigger: 'blur'},
        ],
        direction: [
          {required: true, message: '请输入培训方向', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请输入培训中心名称', trigger: 'blur'},
        ],
        level: [
          {required: true, message: '请选择等级', trigger: 'change'},
        ],
        skillName: [
          {required: true, message: '请输入证书名称', trigger: 'blur'},
        ],
        creationName: [
          {required: true, message: '请输入作品名称', trigger: 'blur'}
        ]
      },
      // 项目经历数据
      projectForm: {
        projectName: '',
        startDay: '',
        endDay: '',
        content: '',
        position: '',
        url: '',
        timeFrame: []
      },
      experienceDV: '',
      // 教育经历数据
      EducationalExperience: {
        education: '',
        professional: '',
        school: '',
        endDay: '',
        startDay: '',
        timeFrame: []
      },
      // 培训经历数据
      TrainsForm: {
        name: '',
        direction: '',
        startDay: '',
        endDay: '',
        content: '',
        timeFrame: []
      },
      // 资格证书数据
      SkillForm: {
        skillName: '',
        dv: ''
      },
      // 作品案例数据
      creationForm: {
        content: '',
        creationName: '',
        // images: [],
        url: '',
      },
      deleteType: '',
      endDatePicker2: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      endDatePicker: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
            // 本月
            {
              text: '至今',
              onClick() {
                this.timeEndNow = true
                this.value3 = '1'
                // console.log(this.value3)
                // picker.$emit('pick', new Date());
              }
            },
        ]
      },
      groupIds2: []
    }
  },
  watch: {
    isShow(el) {
      if (!el) {
        this.getCurrentResume()
      }
    },
    timeEndNow(el) {
      console.log(el)
      this.workExperience.timeFrame =[`${this.value2}-01`, '']
      console.log(1111, this.workExperience.timeFrame)
      this.EducationalExperience.timeFrame =[`${this.value2}-01`, '']
      this.projectForm.timeFrame =[`${this.value2}-01`, '']
      this.TrainsForm.timeFrame =[`${this.value2}-01`, '']

    },
    value2(el) {
      console.log(el)
      if(el === null) {
        this.workExperience.timeFrame =[]
        this.EducationalExperience.timeFrame =[]
        this.projectForm.timeFrame =[]
        this.TrainsForm.timeFrame =[]
        this.value3 = null
      }

    },
    value3(el) {
      console.log(22222, el)
      if(el !== null&&el!=='') {
        this.workExperience.timeFrame =[`${this.value2}-01`, `${el}-01`]
        console.log(1111, this.workExperience.timeFrame)
        this.EducationalExperience.timeFrame =[`${this.value2}-01`, `${el}-01`]
        this.projectForm.timeFrame =[`${this.value2}-01`, `${el}-01`]
        this.TrainsForm.timeFrame =[`${this.value2}-01`, `${el}-01`]
      }

    },
  },
  computed: {
    seMenuInfo() {
      if (this.activeIndex === null) return []
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.navList[this.activeIndex]
    },
    isDeletion() {
      return !!this.$route.query.deletion;
    },
  },
  mounted() {
  },
  created() {
    if(this.$route.query.Upload) {
      this.$emit('openShiBie')
    }
    this.getCurrentResume()
    if(this.isDeletion&&this.form.cateIdsComment?.length===0){
      this.positionShow = !this.positionShow
    }
  },
  methods: {
    openShiBie() {
      this.$emit('openShiBie')
    },

    // 获取个人简历信息
    async getCurrentResume() {
      const {data} = await getCurrentResume()
      // 按时间排序
      if(data[0].project.length>0) {
        const project = data[0].project.slice().sort((a, b) => {
          // 将日期字符串转换为 Date 对象
          const dateA = new Date(a.startDay);
          const dateB = new Date(b.startDay);

          // 比较日期
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        });
        data[0].project = project
      }
      if(data[0].working.length>0) {
        const working = data[0].working.slice().sort((a, b) => {
          // 将日期字符串转换为 Date 对象
          const dateA = new Date(a.startDay);
          const dateB = new Date(b.startDay);

          // 比较日期
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        });
        data[0].working = working
      }
      if(data[0].education.length>0) {
        const education = data[0].education.slice().sort((a, b) => {
          // 将日期字符串转换为 Date 对象
          const dateA = new Date(a.startDay);
          const dateB = new Date(b.startDay);

          // 比较日期
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        });
        data[0].education = education
      }
      if(data[0].memberTrains.length>0) {
        const memberTrains = data[0].memberTrains.slice().sort((a, b) => {
          // 将日期字符串转换为 Date 对象
          const dateA = new Date(a.startDay);
          const dateB = new Date(b.startDay);

          // 比较日期
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        });
        data[0].memberTrains = memberTrains
      }
      if(data[0].memberCreations.length>0) {
        const memberCreations = data[0].memberCreations.slice().sort((a, b) => {
          // 将日期字符串转换为 Date 对象
          const dateA = new Date(a.startDay);
          const dateB = new Date(b.startDay);

          // 比较日期
          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        });
        data[0].memberCreations = memberCreations
      }
      if(!data[0].customJobTag) {
        data[0].customJobTag = []
      }
      if (data[0].expectSalary) {
        console.log(data[0].expectSalary)
      } else {
        console.log(1111)
        data[0].expectSalary = {
          lowSalary: '',
          highSalary: '',
          duration: ''
        }
      }
      await this.$store.commit('userInfo', data[0])
      this.form.areaName = data[0].areaName
      this.dv = data[0].dv
      this.form = {...this.$store.getters.userInfo}
      console.log(this.form)

    },
    disabledDate(time) {
      // 禁用指定日期之前的日期
      const disableBefore = new Date(this.value2);
      if (time.getTime() < disableBefore.getTime()) {
        return true;
      }

      // 禁用当前日期之后的日期
      const today = new Date();
      if (time.getTime() > today.getTime()) {
        return true;
      }

      // 如果日期既不在指定日期之前也不在今天之后，则返回 false
      return false;
    },
    cancel(el) {
      if(el === 'experienceShowShow') {
        this.experienceShowShow = !this.experienceShowShow
      }else if(el === 'projectShow') {
        this.projectShow = !this.projectShow
      }else if(el === 'positionShow') {
        this.positionShow = !this.positionShow
      }else if(el === 'advantageShow') {
        this.advantageShow = !this.advantageShow
      }else if(el === 'educationShow') {
        this.educationShow = !this.educationShow
      }else if(el === 'memberTrainsShow') {
        this.memberTrainsShow = !this.memberTrainsShow
      }else if(el === 'creationShow') {
        this.creationShow = !this.creationShow
      }
      this.timeEndNow = false
      this.value2 = null
      this.value3 = null
      // 清空所有表单数据
      this.workExperience = {
        industry: '',
        company: '',
        content: '',
        department: '',
        endDay: '',
        startDay: '',
        position: '',
        timeFrame: []
      }
      this.EducationalExperience = {
        education: '',
        professional: '',
        school: '',
        startDay: '',
        endDay: '',
        timeFrame: []
      }
      this.projectForm = {
        projectName: '',
        startDay: '',
        endDay: '',
        content: '',
        position: '',
        url: '',
        timeFrame: []
      }
      this.TrainsForm = {
        name: '',
        direction: '',
        startDay: '',
        endDay: '',
        content: '',
        timeFrame: []
      }

      // this.$refs.ruleForm4.resetFields()
    },
    now() {
      this.value3 = "";
      this.timeEndNow = true;
      this.$refs.datePicker.handleClose();
      // this.value3 = "1";
    },
    selectsskillTag(item) {
      if (this.groupIds2.includes(item)) {
        const index = this.groupIds2.indexOf(item)// 判断数组中是否有选中的id,如果有则去掉
        if (index > -1) {
          this.groupIds2.splice(index, 1)
        }
      } else {
        if (this.groupIds2.length>= 3) {
          this.$message({
            message: '最多3个标签',
            type: 'warning'
          })
          return
        }
        this.groupIds2.push(item)
      }
      // this.skillName = item
    },
    selectscertificate(item) {
      this.SkillForm.skillName = item
    },
    openQiwanghangye() {
      this.groupIds = []
      if(this.form.industry) {
        this.groupIds = this.form.industry

      }
      this.dialogVisible = true
    },
    openSuoshuhangye() {
      this.groupIds = []

      if(this.workExperience.industry){
        this.groupIds.push(this.workExperience.industry)

      }
      this.dialogVisible = true
    },
    handleClose2() {
      this.dialogVisible = false
      this.groupIds = []
    },
    eventDelete(name) {
      const arr = this.groupIds
      arr.map((item, index) => {
        if (item === name) {
          this.groupIds.splice(index, 1)
          this.groupIds = this.groupIds.filter(item => name !== item)
        }
      })
    },
    submit3() {
      this.form.industry = this.groupIds
      this.workExperience.industry = this.groupIds

      console.log(this.workExperience.industry)

      this.dialogVisible = false
    },
    selects2(id, index) {
      this.activeIndex = index
      this.activeId = id
    },
    selectss3(name) {
      if (this.groupIds.includes(name)) {
        const index = this.groupIds.indexOf(name)// 判断数组中是否有选中的id,如果有则去掉
        if (index > -1) {
          this.groupIds.splice(index, 1)
        }
      } else {
        if (this.groupIds.length >= 3) {
          this.$message({
            message: '最多只能选择1个',
            type: 'warning'
          })
          return
        }
        if(name === '不限') {
          this.groupIds = ['不限']
          return
        }
        if(this.groupIds.includes('不限')){
          this.$message({
            message: '您已选择不限',
            type: 'warning'
          })
          return
        }
        this.groupIds.push(name)
      }
    },
    // 打开新增技能标签弹窗
    openSkill() {
      console.log(this.form?.customJobTag)
      if(this.form?.customJobTag?.length<3) {
        this.groupIds2 = this.form.customJobTag
        this.dialogVisible4 = true
      }else{
        this.$message.warning('最多只能添加3个标签')
      }
    },
    //  新增技能标签
    async addSkill() {
      if(this.skillName === ''&&this.groupIds2.length===0) {
        this.$message.warning('请输入技能标签')
        return
      }
      await this.getDV()
      if(this.form.customJobTag.length>0) {
        if(this.groupIds2.length>0) {
          this.groupIds2.map(item => {
            this.form.customJobTag.push(item)
          })
        }
        if(this.skillName.length>0) {
          this.form.customJobTag.push(this.skillName)
        }
      }else{
        if(this.groupIds2.length>0) {
          this.groupIds2.map(item => {
            this.form.customJobTag.push(item)
          })
        }
        if(this.skillName.length>0) {
          this.form.customJobTag.push(this.skillName)
        }

        // this.form.customJobTag = [this.skillName]
      }
      this.form.customJobTag = Array.from(new Set(this.form.customJobTag))
      console.log(44444444444, this.form.customJobTag)
      await updateMember(this.form, this.dv)
      await this.getCurrentResume()
      this.$message.success('操作成功')
      this.dialogVisible4 = false
      this.skillName = ''
      // this.form.
    },
    // 删除技能标签
    async deleteSkill(index) {
      if(this.form.customJobTag.length<=1) {
        this.$message.warning('请至少保留一个标签')
        return
      }
      await this.getDV()
      this.form.customJobTag.splice(index, 1)
      await updateMember(this.form, this.dv)
      await this.getCurrentResume()
      this.$message.success('操作成功')
    },
    handleClose4() {
      this.groupIds2 = []
      this.dialogVisible4 = false
      this.skillName = ''
    },
    zhiweiChange(el) {
      console.log(el)
    },
    editInput(value) {
      this.form.expectSalary.lowSalary =
          ("" + value) // 第一步：转成字符串
              .replace(/[^\d^.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
              .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
              .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
              .match(/^\d*(\.?\d{0,1})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
      if (value < 1 || value > 100) {
        this.form.expectSalary.lowSalary = '';
      }
    },
    editInput2(value) {
      this.form.expectSalary.highSalary =
          ("" + value) // 第一步：转成字符串
              .replace(/[^\d^.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
              .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
              .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
              .match(/^\d*(\.?\d{0,1})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
      if (value < 1 || value > 100) {
        this.form.expectSalary.highSalary = '';
      }
    },
    editInput3(value) {
      this.form.expectSalary.duration =
          ("" + value) // 第一步：转成字符串
              .replace(/[^\d^.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
              .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
              .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
              .match(/^\d*(\.?\d{0,1})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
      if (value < 1 || value > 24) {
        this.form.expectSalary.duration = '';
      }
    },
    proving(e) {
      var val = e.target.value;
      //限制只能输入一个小数点
      if (val.indexOf(".") !== -1) {
        var str = val.substr(val.indexOf(".") + 1);
        if (str.indexOf(".") !== -1) {
          val = val.substr(0, val.indexOf(".") + str.indexOf(".") + 1);
        }
      }
      e.target.value = val.replace(/[^\d^.]+/g, '');

    },
    eventUpdateExpect() {
      console.log(this.form)
      if (this.form.province) {
        this.cityArr = [this.form.province, this.form.city, this.form.area]
      }
      console.log(this.cityArr)
      this.positionShow = false
    },
    // 提交保存作品案例
    async creationSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.creationForm.dv) {
            const {data} = await updateCreation(this.creationForm, this.creationForm.dv)
            console.log(data)
            this.creationForm = {
              content: '',
              creationName: '',
              // images: [],
              url: '',
            }
          } else {
            const {data} = await addCreation(this.creationForm)
            console.log(data)
          }
          this.$message({
            message: '编辑成功',
            type: 'success'
          });
          await this.getCurrentResume()
          this.creationShow = !this.creationShow
        }
      })
    },
    quxiaoskill() {
      this.SkillForm.skillName = ''
      this.SkillForm.dv = ''
      this.skillShow = !this.skillShow
    },
    // 提交保存资格证书
    async skillSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.SkillForm.skillName = [this.SkillForm.skillName]
          console.log(1111111111111111111111, this.SkillForm)
          if (this.SkillForm.dv!=='') {
            const {data} = await updateMemberSkill(this.SkillForm, this.SkillForm.dv)
            console.log(data)
          } else {
            delete this.SkillForm.dv
            const {data} = await addMemberSkill(this.SkillForm)
            console.log(data)
          }
          this.$message({
            message: '编辑成功',
            type: 'success'
          });
          this.SkillForm.skillName = ''
          this.SkillForm.dv = ''
          await this.getCurrentResume()
          this.skillShow = !this.skillShow
        }
      })
    },
    // 提交保存培训经历
    async TrainsSubmit(formName) {
      console.log(this.TrainsForm.timeFrame)
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.TrainsForm.startDay = this.TrainsForm.timeFrame[0]
          this.TrainsForm.endDay = this.TrainsForm.timeFrame[1]
          if (this.TrainsForm.dv) {
            const {data} = await updateMemberRrain(this.TrainsForm, this.TrainsForm.dv)
            console.log(data)
          } else {
            const {data} = await addMemberRrain(this.TrainsForm)
            console.log(data)
          }
          this.timeEndNow = false
          this.TrainsForm.name = ''
          this.TrainsForm.dv = ''
          this.TrainsForm.timeFrame = []
          this.TrainsForm.content = ''
          this.TrainsForm.direction = ''
          this.TrainsForm.startDay = ''
          this.TrainsForm.endDay = ''
          this.$message({
            message: '编辑成功',
            type: 'success'
          });
          this.value2 = null
          this.value3 = null
          await this.getCurrentResume()
          this.memberTrainsShow = !this.memberTrainsShow
        }
      })
    },
    // 提交保存项目经历
    async projectSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.projectForm.startDay = this.projectForm.timeFrame[0]
          this.projectForm.endDay = this.projectForm.timeFrame[1]
          if (this.projectForm.dv) {
            const {data} = await updateMemberProject(this.projectForm, this.projectForm.dv)
            console.log(data)
            this.projectForm = {
              projectName: '',
              startDay: '',
              endDay: '',
              content: '',
              position: '',
              url: '',
              timeFrame: []
            }
          } else {
            const {data} = await memberProject(this.projectForm)
            console.log(data)
          }
          this.value2 = null
          this.value3 = null
          this.$message({
            message: '编辑成功',
            type: 'success'
          });
          this.timeEndNow = false
          this.$refs.ruleForm3.resetFields()
          await this.getCurrentResume()
          this.projectShow = !this.projectShow
        }
      });
    },
    // 提交保存教育经历
    async educationSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.EducationalExperience.startDay = this.EducationalExperience.timeFrame[0]
          this.EducationalExperience.endDay = this.EducationalExperience.timeFrame[1]
          if (this.EducationalExperience.dv) {
            const {data} = await updateMemberEducation(this.EducationalExperience, this.EducationalExperience.dv)
            console.log(data)

          } else {
            const {data} = await memberEducation(this.EducationalExperience)
            console.log(data)
          }
          this.EducationalExperience = {
            education: '',
            professional: '',
            school: '',
            endDay: '',
            startDay: '',
            timeFrame: []
          }
          this.timeEndNow = false
          this.$message({
            message: '编辑成功',
            type: 'success'
          });
          this.value2 = null
          this.value3 = null
          this.$refs.ruleForm2.resetFields()
          await this.getCurrentResume()
          this.educationShow = !this.educationShow
        }
      });
    },
    handleClose3() {
      this.dialogVisible3 = false
    },
    // 打开删除确认框
    deleteExperience(dv, type) {
      // 定义经历类型的字典，键为经历类型字符串，值为对应的经历名称
      const experienceTypes = {
        'experience': '工作经历',
        'project': '项目经历',
        'school': '教育经历',
        'train': '培训经历',
        'skill': '资格证书',
        'creation': '作品案例'
      };
      if(experienceTypes[type] === '培训经历') {
        if(this.form.memberTrains.length<=1) {
          this.$message({
            message: '最少保留一条',
            type: 'warning'
          });
          return
        }
      }
      if(experienceTypes[type] === '作品案例') {
        if(this.form.memberCreations.length<=1) {
          this.$message({
            message: '最少保留一条',
            type: 'warning'
          });
          return
        }
      }
      if(experienceTypes[type] === '资格证书') {
        if(this.form.skill.length<=1) {
          this.$message({
            message: '最少保留一条',
            type: 'warning'
          });
          return
        }
      }
      if(experienceTypes[type] === '工作经历') {
        if(this.form.working.length<=1) {
          this.$message({
            message: '最少保留一条',
            type: 'warning'
          });
          return
        }
      }
      if(experienceTypes[type] === '项目经历') {
        if(this.form.project.length<=1) {
          this.$message({
            message: '最少保留一条',
            type: 'warning'
          });
          return
        }
      }
      if(experienceTypes[type] === '教育经历') {
        if(this.form.education.length<=1) {
          this.$message({
            message: '最少保留一条',
            type: 'warning'
          });
          return
        }
      }
      this.dialogName = experienceTypes[type] || '未知经历类型';

      this.deleteType = type
      this.dialogVisible3 = true
      this.experienceDV = dv
    },
    // 删除
    async confirmDelete() {
      if (this.deleteType === 'experience') {

        const {data} = await deleteMemberWorking(this.experienceDV)
        console.log(data)
      } else if (this.deleteType === 'project') {
        const {data} = await deleteMemberProject(this.experienceDV)
        console.log(data)
      } else if (this.deleteType === 'school') {
        const {data} = await deleteMemberEducation(this.experienceDV)
        console.log(data)
      } else if (this.deleteType === 'train') {
        const {data} = await deleteMemberRrain(this.experienceDV)
        console.log(data)
      } else if (this.deleteType === 'skill') {
        const {data} = await deleteMemberSkill(this.experienceDV)
        console.log(data)
      } else if (this.deleteType === 'creation') {
        const {data} = await deleteCreation(this.experienceDV)
        console.log(data)
      }
      this.$message({
        message: '删除成功',
        type: 'success'
      });
      await this.getCurrentResume()
      this.dialogVisible3 = false
    },
    // 编辑
    eventUpdate(item, type) {
      console.log(item)
      if(item.startDay) {
        this.value2 = item.startDay.slice(0,7)
        if(!item.endDay) {
          this.timeEndNow = true

        }
        this.value3 = item.endDay?item.endDay.slice(0,7): ''
      }
      // console.log('value', this.value2, this.value3)
      if (type === 'work') {
        this.workExperience = {...item, timeFrame: [item.startDay, item.endDay]}
        this.experienceShowShow = !this.experienceShowShow
      } else if (type === 'project') {
        this.projectForm = {...item, timeFrame: [item.startDay, item.endDay]}
        this.projectShow = !this.projectShow
      } else if (type === 'school') {
        this.EducationalExperience = {...item, timeFrame: [item.startDay, item.endDay]}
        this.educationShow = !this.educationShow
      } else if (type === 'train') {
        this.TrainsForm = {...item, timeFrame: [item.startDay, item.endDay]}
        this.memberTrainsShow = !this.memberTrainsShow
      } else if (type === 'skill') {
        if(item.skillName.length === 1) {
          console.log(item.skillName)
          this.SkillForm.skillName = item.skillName[0]
        }
        this.SkillForm.dv = item.dv
        this.skillShow = !this.skillShow
      } else if (type === 'creation') {
        this.creationForm = {...item}
        this.creationShow = !this.creationShow
      }
    },
    // 提交添加工作经历
    async submit2(formName) {
      console.log(formName)
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.workExperience.startDay = this.workExperience.timeFrame[0]
          this.workExperience.endDay = this.workExperience.timeFrame[1]
          if (this.workExperience.dv) {
            const {data} = await updateMemberWorking(this.workExperience, this.workExperience.dv)
            console.log(data)
            this.workExperience = {
              industry: '',
              company: '',
              content: '',
              department: '',
              endDay: '',
              startDay: '',
              position: '',
              timeFrame: []
            }
          } else {
            const {data} = await memberWorking(this.workExperience)
            console.log(data)
          }
          this.groupIds = []
          this.$refs.ruleForm.resetFields();
          this.value2 = ''
          this.value3 = null
          this.timeEndNow = false
          this.$message({
            message: '编辑成功',
            type: 'success'
          });
          await this.getCurrentResume()
          this.experienceShowShow = !this.experienceShowShow
        }
      })
    },
    // 修改前调用获取dv
    async getDV() {
      const {data} = await getCurrentResume()
      this.dv = data[0].dv
    },
    // 提交保存基本信息
    async submit(el) {
      console.log(this.cityArr)
      if(this.form.cateIdsComment.length ===0){
        this.$message({
          message: '请选择期望职位',
          type: 'warning'
        });
        return
      }
      if (this.cityArr.length !== 0) {
        this.form.province = this.cityArr[0]
        this.form.city = this.cityArr[1]
        this.form.area = this.cityArr[2]
        // this.form.area = this.cityArr.pop()
      }
      await this.getDV()
      const res = await updateMember(this.form, this.dv)
      console.log(res)
      await this.getCurrentResume()
      this.$message({
        message: '编辑成功',
        type: 'success'
      });
      this.groupIds = []

      if (el === 'positionShow') {
        this.positionShow = !this.positionShow
      } else if (el === 'advantageShow') {
        this.advantageShow = !this.advantageShow
      }
    },
    celect(el) {
      this.current = el
    },
  }
}
</script>
<style lang="scss">
.time-picker{
  .el-icon-date {
    margin-left:5px;
    width: 30px;
    color: #333;
    &::before {
      content: '至今';
    }
    &::after {
      content: '';
    }
  }
}
.el-date-picker.has-sidebar{
  width: 337px;
  padding-bottom: 50px;
}
.el-picker-panel [slot=sidebar]+.el-picker-panel__body, .el-picker-panel__sidebar+.el-picker-panel__body{
  margin-left: 0;
}
.el-picker-panel .el-picker-panel__sidebar{
  top:251px!important;
  bottom: 20px !important;
  left: 111px !important;
  border: none !important;
  overflow: hidden;
  .el-picker-panel__shortcut{
    width: 100px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background: #3A76FF;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
  }
  //width: 0;
}
.resume {
  background: #ffffff;
  border-radius: 8px;
  width: 900px;

  .wrapJobIntention {
    padding: 0 24px;
    .btn {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      .left {
        border: 1px solid #ADADAD;
        width: 209px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        font-size: 16px;
        color: #808080;
        cursor: pointer;
      }

      .right2 {
        width: 209px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        font-size: 16px;
        color: #FFFFFF;
        cursor: pointer;
        background: #3A76FF;
      }

      .right {
        width: 209px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        font-size: 16px;
        color: #FFFFFF;
        cursor: pointer;
        background: #E6E6E6;
      }
    }

    .contentss {
      margin-top: 14px;
      display: flex;
      gap: 20px;

      .tagLists {
        display: flex;
        flex-wrap: wrap;
        height: fit-content;
        gap: 16px;

        .current {
          background: #126BD31A !important;
          color: #126BD3 !important;
        }

        .tags {
          cursor: pointer;
          min-width: 103px;
          height: 32px;
          padding: 0 27px;
          background: #F6F6F6;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #333333;
          font-size: 12px;
          border: 1px solid #F6F6F6;

          &:hover {
            background: #fff;
            border: 1px solid #126BD3;
            color: #3A76FF;
          }
        }
      }

      .navigation {
        width: 224px;
        display: flex;
        flex-direction: column;

        .active {
          border-radius: 8px;
          background: #E8F2FE;

          .itemWrap {
            span {
              color: #3A76FF !important;
              font-weight: 600;
            }

            img {
              display: block !important;
            }
          }
        }

        .item {
          cursor: pointer;
          width: 224px;
          height: 42px;

          .itemWrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 13px 13px 13px 22px;

            span {
              font-size: 14px;
              color: #333333;
            }

            img {
              width: 16px;
              height: 16px;
              display: none;
            }
          }
        }
      }
    }

    .border {
      margin: 20px 0;
      border-bottom: 1px solid #E6E6E6;
    }

    .SelectedList {
      margin-top: 14px;
      display: flex;
      align-items: center;
      gap: 14px;

      .item {
        padding: 0 14px;
        height: 34px;
        line-height: 34px;
        border: 1px solid #568AFF;
        background: rgba($color: #1A81F11A, $alpha: 0.1);
        border-radius: 4px;
        color: #3A76FF;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
      }

    }

    .titles {
      font-weight: 500;
      font-size: 16px;
      color: #1F1F1F;

      span {
        margin-left: 16px;
        font-size: 18px;
        color: #3A76FF;
      }
    }
  }
  .footers {
    height: 64px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .list {
        display: flex;
        align-items: center;
        gap: 10px;

        .item {
          display: flex;
          align-items: center;
          gap: 8px;
          height: 32px;
          line-height: 32px;
          padding: 0 14px;
          border-radius: 4px;
          border: 1px solid rgba($color: #000000, $alpha: 0.1);
          background: #F8F8F8;
          justify-content: center;

          .names {
            color: #333333;
            font-size: 14px;
          }

          .delets {
            width: 12px;
            height: 12px;
            cursor: pointer;
          }
        }
      }

      .key {
        color: #555555;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .btn {
      margin-top: 30px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      gap: 10px;

      .left {
        border: 1px solid #ADADAD;
        width: 140px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        font-size: 16px;
        color: #808080;
        cursor: pointer;
      }

      .right2 {
        width: 140px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        font-size: 16px;
        color: #FFFFFF;
        cursor: pointer;
        background: #3A76FF;
      }

      .right {
        width: 140px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        font-size: 16px;
        color: #FFFFFF;
        cursor: pointer;
        background: #E6E6E6;
      }
    }
  }
  .addskill{
    margin-top: 10px;
    .btns {
      margin-top: 20px;
      padding-bottom: 20px;
      justify-content: center;
      display: flex;
      align-items: center;
      gap: 10px;

      .left {
        cursor: pointer;
        width: 140px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: 1px solid #ADADAD;
        border-radius: 100px;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
      }

      .right {
        cursor: pointer;
        width: 140px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #3A76FF;
        border-radius: 100px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .tishi {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 80px;
      height: 80px;
    }

    .desc {
      color: #333333;
      font-size: 20px;
      font-weight: 500;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .desc2 {
      margin-bottom: 50px;
      color: #808080;
      font-size: 14px;
    }

    .btns {
      display: flex;
      align-items: center;
      gap: 10px;

      .left {
        cursor: pointer;
        width: 175px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: 1px solid #ADADAD;
        border-radius: 100px;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
      }

      .right {
        cursor: pointer;
        width: 175px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #3A76FF;
        border-radius: 100px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .el-cascader {
    width: 400px;
  }

  .resumeWrap {
    padding: 16px;

    .advantage {
      margin-top: 20px;
      border-bottom: 1px solid #E6E6E6;
      padding-bottom: 20px;

      .content {
        margin-top: 20px;
        .skillTaglist{
          display: flex;
          align-items: center;
          gap: 20px;
          .item{
            padding: 6px 12px;
            background: #F6F6F6;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            img{
              cursor: pointer;
              position: absolute;
              width: 14px;
              height: 14px;
              top: -7px;
              right: -7px;
            }
            span{
              color: #555555;
              font-size: 14px;
            }
          }
        }

        .editWrap {
          padding: 20px;
          margin-top: 12px;
          background: #F6F6F6;
          border-radius: 8px;
          .qiwangzhiwei{
            .xiexian{
              padding: 0 2px;
            }
            &:last-of-type{
              .xiexian{
                display: none ;
              }
            }
          }

          .sexWrap {
            margin: 0 0 22px 0;
            display: flex;
            flex-direction: column;

            .title {
              margin-top: -5px;
              color: #555555;
              font-size: 14px;
              font-weight: 400;
            }

            .cardList {
              margin-top: 7px;
              display: flex;
              align-items: center;
              gap: 10px;

              .current {
                border: 1px solid #3A76FF !important;
                background: rgba($color: #3A76FF, $alpha: 0.1) !important;
              }

              .item {
                cursor: pointer;
                background: #E6E6E6;
                border-radius: 8px;
                width: 195.5px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #808080;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }

          .btns {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;

            .box2 {
              cursor: pointer;
              background: #3A76FF;
              width: 122px;
              height: 38px;
              border-radius: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #ffffff;
              font-size: 14px;
              font-weight: 400;
            }

            .box1 {
              cursor: pointer;
              background: #ffffff;
              border: 1px solid #E6E6E6;
              width: 122px;
              height: 38px;
              border-radius: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #808080;
              font-size: 14px;
              font-weight: 400;
            }
          }

          .el-select {
            width: 400px;
          }

          .el-input__inner {
            border: 1px solid #E6E6E6 !important;
            border-radius: 8px !important;
          }

          .el-form-item__label {
            line-height: 0 !important;
            color: #555555 !important;
            font-weight: 400 !important;
          }
        }

        .customList {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 10px;

          .item {
            cursor: pointer;
            width: calc(50% - 10px);
            height: 52px;
            background: #F8F8F8;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 12px;

            img {
              width: 18px;
              height: 18px;
            }

            span {
              color: #141414;
              font-size: 15px;
              font-weight: 500;
            }
          }
        }

        .timer {
          display: block;

          .timerWrap {
            display: flex;
            align-items: center;

            .time {
              font-size: 13px;
              color: #808080;
              font-weight: 400;
            }

            img {
              width: 8px;
              height: 20px;
            }
          }
        }

        .school {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 13px 11px;

          .operation {
            display: none;

            .operationWrap {
              display: flex;
              align-items: center;
              gap: 20px;

              .item {
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 4px;

                img {
                  width: 13.5px;
                  height: 13.5px;
                }

                span {
                  font-size: 14px;
                  color: #3A76FF;
                  font-weight: 400;
                }
              }
            }
          }

          &:hover {
            background: #F6F6F6;
            border-radius: 8px;

            .operation {
              display: block !important;
            }

            .timer {
              display: none !important;
            }
          }

          .schoolWrap {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
              width: 40px;
              height: 40px;
            }

            .right {
              display: flex;
              flex-direction: column;
              gap: 4px;

              .name {
                color: #333333;
                font-size: 14px;
                font-weight: 500;
              }

              .desc {
                color: #555555;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }

        .workExperienceList {
          display: flex;
          flex-direction: column;

          .item {
            cursor: pointer;
            padding: 13px 11px;
            border-radius: 8px;

            &:hover {
              background: #F6F6F6;

              .head {
                .operation {
                  display: block !important;
                }

                .timer {
                  display: none !important;
                }
              }
            }

            .head {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 44px;

              .name {
                color: #333333;
                font-size: 16px;
                font-weight: 500;
              }

              .operation {
                display: none;

                .operationWrap {
                  display: flex;
                  align-items: center;
                  gap: 20px;

                  .item {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    img {
                      width: 13.5px;
                      height: 13.5px;
                    }

                    span {
                      font-size: 14px;
                      color: #3A76FF;
                      font-weight: 400;
                    }
                  }
                }
              }
            }
          }
        }

        .font_2 {
          font-weight: 500;
          line-height: 18px;
          font-size: 14px;
          color: #333333;
          .qiwangzhiwei{
            .xiexian{
              padding: 0 2px;
            }
            &:last-of-type{
              .xiexian{
                display: none ;
              }
            }
          }
        }

        .font_1 {
          font-size: 14px;
          font-weight: 400;
          color: #555555;
          line-height: 22px;
        }
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          color: #333333;
          font-size: 18px;
          font-weight: 500;
        }

        .btn {
          border: 1px solid #ADADAD;
          width: 69.5px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          border-radius: 4px;
          cursor: pointer;

          img {
            width: 13.5px;
            height: 13.5px;
          }

          span {
            color: #67686F;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }

    .perfect {
      height: 90px;
      width: 868px;
    }
  }
}
</style>
