<template>
  <div :id="id"></div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader'

export default {
  props: {
    id: {
      type: String,
      default: 'container'
    },
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  watch: {
    info: {
      handler: function () {
        console.log(111111111)
        //DOM初始化完成进行地图初始化
        console.log(Number(this.info.longitude), Number(this.info.latitude))
        this.initMap()
        // 添加 CSS 样式
        const style = document.createElement('style');
        style.innerHTML = `
            .amap-marker-label {
             color: #333333;
             font-weight: 400;
             text-align: center;
             border: none;
             border-radius: 8px;
             padding: 4px 8px;
             }
             `;
        document.head.appendChild(style);
      },
      deep: true
    }
  },
  data() {
    return {
      map: null
    }
  },
  mounted() {
    this.initMap()
    // 添加 CSS 样式
    const style = document.createElement('style');
    style.innerHTML = `
            .amap-marker-label {
             color: #333333;
             font-weight: 400;
             text-align: center;
             border: none;
             border-radius: 8px;
             padding: 4px 8px;
             }
             `;
    document.head.appendChild(style);
  },
  destroyed() {
    // 销毁地图
    if (this.map !== null) {
      this.map.destroy()
    }

  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: "fe972c494694388d383a477b23bc919d",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map(this.id, {  //设置地图容器id
          viewMode: "3D",    //是否为3D地图模式
          zoom: 17,           //初始化地图级别
          center: [Number(this.info.longitude), Number(this.info.latitude)], //初始化地图中心点位置 this.info.latitude], //初始化地图中心点位置
        });
        var marker = new AMap.Marker({
          icon: 'https://static.dasdt.cn/images/adress2.png',
          label: {
            content: this.info.address,
            offset: new AMap.Pixel(0, -30),
            direction: 'center',
            className: 'marker-label', // 给文字添加一个类名

          },
          position: [Number(this.info.longitude), Number(this.info.latitude)],
          map: this.map,
          // content: '上海市静安区上海银行'
        });
        console.log(marker)
      }).catch(e => {
        console.log(e);
      })
    },
  },

}
</script>
<style lang="scss">
#container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.amap-icon img {
  left: 1px !important;
}

#container2 {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
</style>
