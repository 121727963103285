<template>
  <div class="collection">
    <div class="nav">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="职位" name="1">

        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="list" v-if="activeName === '1'">
      <template v-if="list.length!==0">
        <div class="item" v-for="(item, index) in list" :key="index" @click="openDialog(item)">
          <div class="top">
            <div class="left">
              <div class="nameOrPrice">
                <div class="name">{{ item?.job?.job }}</div>
                <div class="price">{{item?.job?.salaryNote}}</div>
              </div>
              <div class="infoList">
                <div class="itemss">
                  <img src="https://static.dasdt.cn/images/adress3.png">
                  <span>{{ item?.job?.cityName }}·{{ item?.job?.areaName }}</span>
                </div>
                <div class="itemss">
                  <img src="https://static.dasdt.cn/images/education.png">
                  <span>{{ item?.job?.education }}</span>
                </div>
                <div class="itemss">
                  <img src="https://static.dasdt.cn/images/experience.png">
                  <span>{{ item?.job?.jobExp }}</span>
                </div>
              </div>
            </div>
            <div class="enterpriseInfo">
              <img style="border-radius: 6.67px" :src="item?.company?.logo?.url" alt="">
              <div class="right">
                <div class="name">{{ item?.company?.name }}</div>
                <div style="display: flex">
                  <div class="tag" v-for="items in item?.company?.tags" :key="items">{{ items }}<span>·</span></div>
                </div>
              </div>
            </div>
                        <div class="status" style="margin-right: 5px;background: #FAAD1426;color: #FAAD14" v-if="item.job?.state === 'CLOSED' || item.job?.state === 'UNPUBLISH'">
                          <span >停止招聘</span>

                        </div>
            <div class="status" @click.stop="cancelByJid(item.job.id)">
              取消收藏
            </div>

          </div>
        </div>
      </template>
      <el-empty v-else description="暂无数据"></el-empty>


    </div>
    <div class="pagination">
      <el-pagination
          background
          layout="prev, pager, next, total"
          :total="totalCount"
          :page-size="5"
          @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
        :modal-append-to-body='false'
        :visible.sync="dialogVisible"
        width="400px"
        title="提示"
        :before-close="handleClose">
      <div class="tishi">
        <img src="https://static.dasdt.cn/images/userCenter/tishi.png">
        <div class="desc">确认取消该职位收藏吗？</div>
        <div class="btns">
          <div class="left">取消</div>
          <div class="right">确认取消</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        :modal-append-to-body='false'
        :visible.sync="dialogVisible"
        width="905px"
        :before-close="handleClose">
      <div class="detailss" slot="footer">
        <div style="display: flex;align-items: center;justify-content: space-between;">
          <div class="titles" style="margin-top: 0px">岗位描述</div>
          <div class="deliver" @click="deliver(jobDetails.job.id)" v-if="!jobDetails.isDeliver&&jobDetails.job?.state !== 'CLOSED'&&jobDetails.job?.state !== 'UNPUBLISH'" >投递简历</div>
          <div v-else>
            <div class="deliver2" v-if="jobDetails.job?.state === 'CLOSED' || jobDetails.job?.state === 'UNPUBLISH'">停止招聘</div>
            <div class="deliver2" v-else-if="jobDetails.isDeliver">已投递</div>
          </div>
        </div>

        <div class="tagList">
          <div class="item" v-for="item in jobDetails.job?.firmTagList" :key="item">{{ item }}</div>
        </div>
        <div class="responsibilityWrap">
          <div class="responsibility">岗位职责：</div>
          <div class="responsibility" style="white-space: pre-wrap;">{{ jobDetails?.job?.duty }}</div>
        </div>
        <div class="responsibilityWrap">
          <div class="responsibility">任职要求：</div>
          <div class="responsibility" style="white-space: pre-wrap;">{{ jobDetails?.job?.required }}</div>
        </div>
        <div class="borderss"></div>
        <div class="titles" style="margin-top: 20px">公司信息</div>
        <div class="companyInfo">
          <img style="border-radius: 6.77px" :src="jobDetails?.company?.logo?.url">
          <div class="right" style="cursor: pointer" @click.stop="goDetails">
            <div class="name">{{ jobDetails?.company?.name }}</div>
            <div style="display: flex">
              <div class="desc" v-for="item in jobDetails?.job?.firmTagList" :key="item">{{ item }}<span>·</span>
              </div>
            </div>
          </div>
        </div>
        <div class="borderss"></div>
        <div class="titles" style="margin-top: 20px">工作地址</div>
        <div class="adresss">
          <div class="item">
            <img src="https://static.dasdt.cn/images/adress2.png">
            <span class="adressShengLue">{{ jobDetails?.company?.address }}</span>
          </div>
          <div class="item">
            <img style="margin-right: 2px" src="https://static.dasdt.cn/images/phone2.png">
            <span>联系电话：{{ jobDetails?.company?.phone }}</span>
          </div>
        </div>
        <div style="height: 300px;width: 100%;margin-top: 16px">
          <MapContainer id="container2" :info="jobDetails?.company"/>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {cancelByJid, collectionList, jobMember} from "@/api/position";
import MapContainer from "@/components/MapContainer/index.vue";

export default {
  components: {MapContainer},
  data() {
    return {
      activeName: '1',
      dialogVisible: false,
      list: [],
      curriculum: [],
      pagination: {
        number: 1,
        limit: 5
      },
      totalCount: 0,
      jobDetails: {},
      type: 'POSITION'
    }
  },
  watch: {
    pagination: {
      handler: function () {
        this.collectionList()
      },
      deep: true
    }
  },
  created() {
    this.collectionList()
  },
  methods: {
    handleClick() {
    },
    async deliver() {
      if(Object.keys(this.$store.getters.userInfo).length>0){
        const info = this.$store.getters.userInfo
        if(info.customJobTag?.length === 0 || !info.birthDay || !info.email || info.working?.length === 0 || info.project?.length === 0 || info.cateIdsComment?.length === 0) {
          this.$message({
            message: '请先完善个人简历',
            type: 'warning'
          });
          this.$router.push('/PersonalCenter?deletion=1')
          return
        }
      }
      const res = await jobMember({
        advantages: '',
        jid: this.jobDetails.job.id,
        bid: this.jobDetails.job.bid,
      })
      if (res.code === 'A00000') {
        this.jobDetails.isDeliver = !this.jobDetails.isDeliver
        this.jobDetails.job.state = 'PUBLISHED'
        this.$message({
          message: '投递成功',
          type: 'success'
        })
      }else{
        this.$message({
          message: res.message,
          type: 'warning'
        })
      }
      console.log(res)
    },
    async cancelByJid(dv) {
      const {data} = await cancelByJid(dv)
      console.log(data)
      this.$message.success('操作成功')
      this.$emit('cancel')
      await this.collectionList()
    },
    handleCurrentChange(el) {
      this.pagination.number = el
    },
    async collectionList() {
      const res = await collectionList({type: this.type, direction: [{'name':'updateTime','direction':'DESC'}]}, this.pagination)
      this.list = res.data
      this.totalCount = res.totalCount
    },
    openDialog(item) {
      this.jobDetails = item
      this.dialogVisible = true

    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>


<style lang="scss">
.collection {
  .pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }

  .el-dialog__footer {
    text-align: left !important;
    overflow-y: auto;
  }

  .detailss {
    border-radius: 8px;
    padding: 16px;
    background: #fff;
    .deliver2 {
      margin-bottom: 16px;
      background: #475774;
      border-radius: 4px;
      width: 140px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ADADAD;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }

    .deliver {
      margin-bottom: 16px;
      background: #3A76FF;
      border-radius: 4px;
      width: 140px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }

    .ViewMores {
      margin: 32px auto;
      width: 200px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #185BE0;
      color: #185BE0;
      font-size: 14px;
      font-weight: 400;
      border-radius: 4px;
      cursor: pointer;
    }

    .adresss {
      display: flex;
      align-items: center;

      .item {
        width: 50%;
        display: flex;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          color: #555555;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .companyInfo {
      display: flex;
      align-items: center;
      gap: 6px;

      img {
        width: 44px;
        height: 44px;
      }

      .right {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .name {
          color: #333333;
          font-size: 16px;
          font-width: 500;
        }

        .desc {
          color: #808080;
          font-size: 14px;
          display: flex;

          span {
            display: none;
          }
        }

        .desc:not(:last-child) {
          span {
            display: block !important;
          }
        }
      }
    }

    .borderss {
      margin-top: 20px;
      border-bottom: 1px solid #E6E6E6;
    }

    .responsibilityWrap {
      margin-top: 20px;

      .responsibility {
        color: #333333;
        font-size: 14px;
        line-height: 26px;
        font-width: 400;
      }
    }

    .titles {
      margin-bottom: 16px;
      margin-top: 32px;
      color: #141414;
      font-size: 18px;
      font-width: 500;

    }
  }

  .tishi {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 80px;
      height: 80px;
    }

    .desc {
      color: #333333;
      font-size: 20px;
      font-weight: 500;
      margin-top: 16px;
      margin-bottom: 50px;
    }

    .btns {
      display: flex;
      align-items: center;
      gap: 10px;

      .left {
        cursor: pointer;
        width: 175px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: 1px solid #ADADAD;
        border-radius: 100px;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
      }

      .right {
        cursor: pointer;
        width: 175px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #3A76FF;
        border-radius: 100px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .curriculum {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 18px;

    .item {
      background: #fff;
      border-radius: 8px;
      height: 108px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 16px;

      .right {
        display: flex;
        align-items: center;
        gap: 20px;

        .status2 {
          cursor: pointer;
          color: #808080;
          font-size: 14px;
          font-weight: 400;
        }

        .status3 {
          cursor: pointer;
          color: #3A76FF;
          font-size: 14px;
          font-weight: 400;
        }
      }

      img {
        height: 100%;
        width: 200px;
      }

      .middle {
        flex: 1;
        margin-left: 16px;
        display: flex;
        flex-direction: column;

        .name {
          color: #1F1F1F;
          font-weight: 500;
          font-size: 16px;
        }

        .time {
          margin: 8px 0;
          color: rgba($color: #1F1F1F, $alpha: 0.6);
          font-size: 14px;
          font-weight: 400;
        }

        .price {
          font-size: 24px;
          color: #FD5056;
          font-weight: 500;

          span {
            font-size: 14px;
          }
        }
      }
    }
  }

  .list {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .item {
      cursor: pointer;
      background: #ffffff;
      border-radius: 8px;
      padding: 16px;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .status2 {
          background: rgba($color: #F67830, $alpha: 0.15);
          border-radius: 0 8px 0 8px;
          padding: 4px 10px 4px 10px;
          color: #F67830;
          font-size: 14px;
          font-weight: 400;
        }

        .status3 {
          background: rgba($color: #FAAD1426, $alpha: 0.15);
          border-radius: 0 8px 0 8px;
          padding: 4px 10px 4px 10px;
          color: #FAAD1426;
          font-size: 14px;
          font-weight: 400;
        }

        .status {
          background: #F8F8F8;
          border-radius: 0 8px 0 8px;
          padding: 4px 10px 4px 10px;
          color: #ADADAD;
          font-size: 14px;
          font-weight: 400;
        }

        .enterpriseInfo {
          flex: 1;
          //margin-left: 50px;
          display: flex;
          align-items: center;
          gap: 6px;

          img {
            width: 40px;
            height: 40px;
          }

          .right {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .name {
              font-size: 16px;
              color: #282828;
              font-weight: 500;
            }

            .tag {
              color: #808080;
              font-size: 14px;
              line-height: 16px;
            }
          }
        }

        .left {
          min-width: 400px;
          .infoList {
            margin-top: 10px;
            display: flex;
            align-items: center;
            gap: 24px;

            .itemss {
              display: flex;
              align-items: center;
              gap: 6px;

              img {
                width: 16px;
                height: 16px;
              }

              span {
                color: rgba($color: #000000, $alpha: 0.65);
                font-size: 14px;
                font-width: 400;
              }
            }
          }

          .nameOrPrice {
            display: flex;
            align-items: center;
            gap: 14px;

            .name {
              color: #141414;
              font-size: 16px;
              font-weight: 500;
            }

            .price {
              color: #FD5056;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }

      }
    }
  }

  .nav {
    padding: 8px 16px 0 16px;
    background: #fff;
    border-radius: 8px;

    .el-tabs__nav-wrap::after {
      height: 0px !important;
      background-color: transparent !important;
    }

    .el-tabs__header {
      margin: 0 0 2px;;
    }

    .el-tabs__active-bar {
      background-color: #185BE0 !important;
      //height: 3px;
      //width: 68px !important;

    }

    .el-tabs__item.is-active {
      color: #185BE0 !important;
    }

    .el-tabs__item {
      &:hover {
        color: #185BE0 !important;

      }
    }
  }
}
</style>
